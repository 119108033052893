<script>
function intercom(e) {

    if(!window.Intercom) return; // fall through

    Intercom("show");

    e.preventDefault();
}
</script>
<a href="https://help.parkingboss.com/" target="_blank" rel="intercom" on:click={intercom}>Get Help</a>