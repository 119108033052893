
import { router as page } from "../util/router";
import { qs } from "@parkingboss/utils";
import postal from "postal";
import moment from "moment";
//import Promise from "bluebird";
import * as _ from "lodash-es";
import jwt_decode from "jwt-decode";

window.postal = postal;

// @codekit-prepend "../node_modules/shared.static/scripts/js/ready.js";
(function() {
    
    // wait a minimum of 1s
    
    //var delay = Promise.delay(1000);
    
    document.documentElement.addEventListener("ready", function(e) {
        //delay.then(function() {
            document.documentElement.classList.add("ready");
        //});
    });
}());

// @codekit-prepend "../node_modules/shared.static/scripts/js/ui.init.js";
(function() {

    var Supports = {}
 
    Supports.events = Supports.events || {};
     
     
    // from modernizr
    Supports.events.touch = Supports.touch = (function() {
        var bool;
        if(('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
          bool = true;
        }/*
 else {
          var query = ['@media (',prefixes.join('touch-enabled),('),'heartz',')','{#modernizr{top:9px;position:absolute}}'].join('');
          testStyles(query, function( node ) {
            bool = node.offsetTop === 9;
          });
        }
*/
        return bool;
    })();
     
     
    if(Supports.touch && "classList" in document.documentElement) document.documentElement.classList.add("touch");
    else if("classList" in document.documentElement) document.documentElement.classList.add("hoverable");
    if("addEventListener" in document) document.addEventListener("touchstart", function(){}, true); // empty touchstart for touch
     
})();
// @codekit-prepend "../node_modules/shared.static/scripts/js/page.init.js";
(function(page, qs) {
    
    var prev;

    page("*", function(ctx, next) {

		if(!!qs) ctx.query = qs.parse(ctx.querystring);

		ctx.referer = prev;
        prev = ctx.path;

		next();
	
	});
    
}(page));

// @codekit-prepend "../node_modules/shared.static/scripts/js/page.exit.js";
(function(page, root) {
    
    page.exit("*", function(ctx, next) {
        //document.title = "Loading...";
        root.removeAttribute("data-records");
        root.removeAttribute("data-record");
        root.removeAttribute("data-record-view");
        next();
    });

    
}(page, document.documentElement));

// @codekit-prepend "../node_modules/shared.static/scripts/js/track.js";
// DOM Events
(function() {

    // send
    document.addEventListener("track", function(e) {
       var data = e.detail;
       if(!data) return;
       
       // analytics
        if (!!window._gaq) _gaq.push(['_trackEvent', data.category, data.action, data.label]);
        
        // need to add support for universal analytics
        if(!!window.ga) ga('send', 'event', data.category, data.action, data.label);  // value is a number.
    });
    
}());

(function(page, ga, appInsights) {

    if(!page) return;
    

    page("*", function(ctx, next) {
	
		//console.log("*");
	
        // run analytics
        
        _.invoke(ga, "push", ['_trackPageview', ctx.canonicalPath]);
        _.invoke(appInsights, "trackPageView", ctx.canonicalPath);
		
        //if (!!window._gaq) _gaq.push(['_trackPageview', ctx.canonicalPath]);
        // if (!!window.ga) {
        //     ga('set', 'page', ctx.canonicalPath);
        //     ga('send', 'pageview');
        // }
		

        //if(!!window.appInsights && appInsights.trackPageView) appInsights.trackPageView(ctx.canonicalPath);
        // if(!!window.rg4js) rg4js("trackEvent", {
        //     type: "pageView",
        //     path: ctx.canonicalPath,
        // });
        // else if(!!window.Raygun && !!Raygun.trackEvent) Raygun.trackEvent('pageView', { path: ctx.canonicalPath });

	
		next();
	
	});

    
}(page, window._gaq, window.appInsights));

// @codekit-prepend "../node_modules/shared.static/scripts/js/form.js";
// DOM Events
(function(root) {

    //console.log("init events");

    //console.log(root);
    //var delegate = new Delegate(root);

    //console.log(root.root);

    //var root = new Delegate(document.documentElement);

    /*document.documentElement.addEventListener("change", function(e) {
       console.log("global",e);
    });

    delegate.on("change", function(e) {
       console.log("global",e);
    });*/

    root.addEventListener("change", function(e) {
        //console.log("change", e);
        if(!_.invoke(e, "target.matches", "select")) return;

        var select = e.target;
        select.blur();
        //var options = this.getElementsByTagName("option");
        var txt = select.parentNode.querySelector("select+span");
        if(!txt) select.after(txt = document.createElement("span"));
        //console.log("text=" + select.options[select.selectedIndex || 0].innerText || select.options[select.selectedIndex || 0].text);
        var option = select.options[select.selectedIndex || 0];
        if(!!option) txt.textContent = option.title || option.textContent || option.text;

    });
    
    // delegate.on("change", "select", function(e) {
        
    //     //console.log(e);
        
    //     var select = this;
    //     select.blur();
    //     //var options = this.getElementsByTagName("option");
    //     var txt = this.parentNode.querySelector("select+span");
    //     if(!txt) select.after(txt = document.createElement("span"));
    //     //console.log("text=" + select.options[select.selectedIndex || 0].innerText || select.options[select.selectedIndex || 0].text);
    //     var option = select.options[select.selectedIndex || 0];
    //     if(!!option) txt.textContent = option.title || option.textContent || option.text;

    //     //console.log(option);
        
    // });

    root.addEventListener("change", function(e) {

        if(!_.invoke(e, "target.matches", "input[type='date']")) return;

        //delegate.on("change", "input[type='date']", function (e) {
    	//console.log("input date change");

        //console.log("date change", this, e.target);

        //console.log("input date change", this.value);

        var target = e.target;

    	var date = moment(target.value);

        //var format = date.isSame(moment(), 'year') ? "ddd, MMM D" : "ddd, MMM D, YYYY";
        //console.log("moment", date);

    	var txt = date.isValid() ? date.format(target.getAttribute("data-format") || "ddd, MMM D, YYYY") : "";
    	var span = target.parentNode.querySelector("input[type='date'] + span");
        if (!span) {
			span = document.createElement("span");
			target.after(span);
		}
        //console.log(txt);
        span.textContent = txt; // only change if something new
    });
    
    var onchange = function(e) {
        //console.log("onchange", e);
        //console.log(this);
        var target = e.target;
        var label = target.closest("label");
        if(!!label) label.classList[!!target.value ? "add" : "remove"]("value");
	};
    
    _.each([ "input", "textinput", "change", "keypress", "keyup", "keydown"], function(ev) {
        //delegate.on(ev, "input,textarea,select", onchange);
        root.addEventListener(ev, function(e) {

            if(!_.invoke(e, "target.matches", "input,textarea,select")) return;

            onchange(e);

        });
    });
    
    root.addEventListener("focus", function(e) {

        if(!_.invoke(e, "target.matches", "input,textarea,select")) return;
        //delegate.on("focus", "input,textarea,select", function(e) {

        var target = e.target;

        var label = target.closest("label");
        if(!!label) label.classList.add("focused");
        var f = target.closest("fieldset");
        if(!!f) target.closest("fieldset").classList.add("focused");
    });
    root.addEventListener("focusin", function(e) {

        if(!_.invoke(e, "target.matches", "input,textarea,select")) return;
        //delegate.on("focusin", "input,textarea,select", function(e) {

        var target = e.target;
        var label = target.closest("label");
        if(!!label) label.classList.add("focused");
        var f = target.closest("fieldset");
        if(!!f) target.closest("fieldset").classList.add("focused");
    });

    
    root.addEventListener("blur", function(e) {

        if(!_.invoke(e, "target.matches", "input,textarea,select")) return;
        //delegate.on("blur", "input,textarea,select", function(e) {

        var target = e.target;

        var label = target.closest("label");
        if(!!label) label.classList.remove("focused");
        
        var f = target.closest("fieldset");
        if(!!f) target.closest("fieldset").classList.remove("focused");
    });

    root.addEventListener("focusout", function(e) {

        if(!_.invoke(e, "target.matches", "input,textarea,select")) return;
        //delegate.on("focusout", "input,textarea,select", function(e) {

        var target = e.target;

        var label = target.closest("label");
        if(!!label) label.classList.remove("focused");
        
        var f = target.closest("fieldset");
        if(!!f) target.closest("fieldset").classList.remove("focused");
    });
    
    root.addEventListener("reset", function(e) {

        if(!_.invoke(e, "target.matches", "form")) return;
        //delegate.on("reset", "form", function(e) {
        //if(window.console) console.log("doc-bound reset");

        var target = e.target;

        // force the reset - circular?
        //this.reset();

        //console.log("form.reset");
        
        _.each(target.querySelectorAll("input,textarea,select,button"), function(input) {
            input.dispatchEvent(new CustomEvent("change", { "bubbles": true }));
            window.setTimeout(function () { input.dispatchEvent(new CustomEvent("change", { "bubbles": true })); }, 10);
        });
        
    });
    
    root.addEventListener("keydown", function(e) {

        // if(!_.invoke(e, "target.matches", "input,textarea,select")) return;
        //delegate.on("keydown", function(e) {
        if(e.ctrlKey || e.metaKey) switch (String.fromCharCode(e.which).toLowerCase()) {
            case 'p':
                e.preventDefault();
                
                (document.body || document.getElementsByTagName("body")[0]).dispatchEvent(new CustomEvent("print", { "bubbles": true }));
                
                document.documentElement.dispatchEvent(new CustomEvent("track", {
                    bubbles: true,
                    detail: {
                        category: "keyboard", 
                        action: "print", 
                        label: window.location.href,
                    },
                }));
                
                return false;
                break;
        }
    });
    
}(document.documentElement));

// @codekit-prepend "../node_modules/shared.static/scripts/js/back.js";
(function(root, Delegate) {

    root.addEventListener("back", function(e) {
    
    //var delegate = new Delegate(root);
    //delegate.on("back", function(e) {
        
        if(!!window.history && window.history.length > 0) window.history.go(-1);
        else window.location.href = document.referrer || document.querySelector("body > nav.main ul a:first-of-type").href; 
        
        
    });
    
}(document.documentElement));

// @codekit-prepend "../node_modules/shared.static/scripts/js/api.init.js";
var ParkIQ = window.ParkIQ = ParkIQ || {};

ParkIQ.API = ParkIQ.API || {
	__base:null,
};

//if(window.location.hostname === "localhost") ParkIQ.API.__base = "https://api-dev.parkingboss.com/";
if(window.location.hostname === "homeone.local") ParkIQ.API.__base = "https://api-dev.parkingboss.com/";

(function(api, postal) {

    _.extend(api, {
        base: function(val) {
            if(!!!val) api.__base = val;
            return api.__base || (api.__base = document.querySelector("link[rel~='index'][rel~='api'][type~='application/json']").href);
        },
        cache: {
            _items: {},
            _timestamps:{},
            get:function(id) {
                if(!id) return null;
                var obj = api.cache._items[id];
                if(!obj) return null;
                return {
                    id: id,
                    value: obj,
                    timestamp:api.cache._timestamps[id],
                };
            },
            set:function(id, value, timestamp) {
                if(!id || !value || !timestamp) return null;
                api.cache._items[id] = value;
                api.cache._timestamps[id] = timestamp;
                return api.cache.get(id);
            },
        },
        _error:function(response, json) {

            //console.log("_error", response, json);

            var error = new Error(!!json && !!json.message ? json.message : !!response ? response.status + " " + response.statusText : "Fetch error"); // message text
            if(!!response) {
                error.status = response.status;
                error.statusText = response.statusText;
                error.type = response.type;
                error.url = response.url;
                error.response = response;
            }
            error.data = json;
            var customData = {};
            if(!!json) customData.data = json;
            if(!!response) _.extend(customData, response);

            try {
                throw error;
            } catch (e) {
                if(!!window.rg4js) {
                    //console.log("rg4js sending", e, customData);
                    rg4js('send', {
                        error: e,
                        customData:customData,
                    });
                } else if(!!window.Raygun && Raygun.send) Raygun.send(e, !!json ? _.extend({
                    data:json,
                }, response) : response);
            }



            return error;
            //throw error;
        },
        _status: function(response) {
            if (!!response.ok) return Promise.resolve(response);

            //throw api._error(response);
            return Promise.reject(api._error(response));

        },
        _json: function(response) {
            return response.json();
        },
        _catch:function(error) {

            //console.log("onError", error);

            api._unauthorized(error); // this would succeed if unauthorized, reject if not for continued catching

            // do some automated error catching here..
            /*
            if(!!error && error.status > 0) {
                switch(error.status) {
                    case 404:
                        //alert("Not Found");
                        break;
                    case 401:
                        document.documentElement.dispatchEvent(new CustomEvent("unauthorized", { bubbles: true }));
                        break;
                    default:
                        //alert("Loading Error");
                        break;
                }
                //return error;
                //return;
            }
            */

            if(window.Raygun && Raygun.send) Raygun.send(error, error.response) // no custom data;

            //alert("Network Failure");

            return Promise.reject(error); // need to keep the rejection flowing...

        },
        _notfound:function(error) {
            if(!!error && error.status == 404) return Promise.resolve({ status: error.status });
            return Promise.reject(error);
        },
        _unauthorized:function(error) {
            if(!!error && error.status == 401) {
                document.documentElement.dispatchEvent(new CustomEvent("unauthorized", { bubbles: true }));
                //return Promise.resolve({ status: error.status });
            }
            return Promise.reject(error);
        },
        _process:function(response) {

            //console.log(response);
            //response = response.clone();


            return response.text()
            .then(function(responseText) {

                //console.log("processed text");
                //console.log(responseText);


                return Promise.resolve(responseText)
                .then(JSON.parse)
                .then(function(json) {

                    //console.log("processed json");
                    //console.log(data);
                    //console.log(response.headers);
                    var date = response.headers.get("Date") || response.headers.get("date");
                    //console.log("date", date);
                    if(!!date) json.date = new Date(date).toISOString();
                    var modified = response.headers.get("Last-Modified") || response.headers.get("last-modified");
                    if(!!modified) json.modified = new Date(modified).toISOString();
                    json.status = response.status;

                    if(response.ok) return Promise.resolve(json);

                    //throw api._error(response, json);
                    return Promise.reject(api._error(response, json));

                }, function(jsonParseError) {

                    //console.log("no json");

                    // no json
                    if(response.ok) return Promise.resolve({ status: response.status, body: responseText });

                    /*
                    throw api._error(response, {
                        message: responseText
                    });
                    */
                    return Promise.reject(api._error(response, {
                        message: responseText
                    }));

                });


            }, function(textParseError) {

                // no response text
                if(response.ok) return { status: response.status };

                //throw api._error(response);
                return Promise.reject(api._error(response));

            })
            .catch(api._catch); // this will pass back any rejections

        },
        _entities:function(json) {
            if(!json) return json;

            var location = _.get(json, ["locations", "items", _.get(json, "locations.item")], json.location);

            var user = _.get(json, ["users", "items", _.get(json, "users.item")]) || _.get(json, "users.item", json.user);
            //console.log("entities.user", user);


        //console.log("entities.location", location);
            var attendant = _.get(json, ["attendants", "items", _.get(json, "attendants.item")]);

            // tenants?
            // vehicles?
            // permits?

            if(!!postal && !!user) _.each([
                //"user",
                //"user.updated",
                "user.item"
            ], function(topic) {
                postal.publish({
                    topic   : topic,
                    data    : {
                        generated: json.generated,
                        requested: json.requested,
                        item: user,
                    }
                });
            });

            if(!!postal && !!location) _.each([
                //"location",
               //"location.updated",
                "location.item",
                //"locations.item"
            ], function(topic) {
                postal.publish({
                    topic   : topic,
                    data    : {
                        generated: json.generated,
                        requested: json.requested,
                        item: location,
                    }
                });
            });

            if(!!postal && !!attendant) _.each([
                //"attendant",
                //"attendant.updated",
                "attendant.item"
            ], function(topic) {
                postal.publish({
                    topic   : topic,
                    data    : {
                        generated: json.generated,
                        requested: json.requested,
                        item: attendant,
                    }
                });
            });

            return json;
        },
    });
    api.response = {
        ok: api._status,
        status: api._status,
        json: api._json,
        process: api._process,
        onError:api._catch,
        error:api._catch,
        entities:api._entities,
        notfound:api._notfound,
        unauthorized:api._unauthorized,
    };

})(ParkIQ.API, window.postal);

// @codekit-prepend "../node_modules/shared.static/scripts/js/api.storage.js";
(function(exports) {

    var docCookies = {
        defaultPath: "/",
        getItem: function (sKey) {
            //if(navigator.cookieEnabled === false) return null;
            if (!sKey) { return null; }
            return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
        },
        setItem: function (sKey, sValue, vEnd, sPath, sDomain, bSecure) {
            //if(navigator.cookieEnabled === false) return false;
            if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) { return false; }
            var sExpires = "";
            var sPathOrDefault = sPath || docCookies.defaultPath;
            if (vEnd) {
                switch (vEnd.constructor) {
                    case Number:
                        sExpires = vEnd === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; max-age=" + vEnd;
                        break;
                    case String:
                        sExpires = "; expires=" + vEnd;
                        break;
                    case Date:
                        sExpires = "; expires=" + vEnd.toUTCString();
                        break;
                }
            }
            document.cookie = encodeURIComponent(sKey) + "=" + encodeURIComponent(sValue) + sExpires + (sDomain ? "; domain=" + sDomain : "") + (sPathOrDefault ? "; path=" + sPathOrDefault : "") + (bSecure ? "; secure" : "");
            return true;
        },
        removeItem: function (sKey, sPath, sDomain) {
            //if(navigator.cookieEnabled === false) return false;
            if (!this.hasItem(sKey)) { return false; }
            var sPathOrDefault = sPath || docCookies.defaultPath;
            document.cookie = encodeURIComponent(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + (sDomain ? "; domain=" + sDomain : "") + (sPathOrDefault ? "; path=" + sPathOrDefault : "");
            return true;
        },
        hasItem: function (sKey) {
            //if(navigator.cookieEnabled === false) return false;
            if (!sKey) { return false; }
            return (new RegExp("(?:^|;\\s*)" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
        },
        keys: function () {
            //if(navigator.cookieEnabled === false) return [];
            var aKeys = document.cookie.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, "").split(/\s*(?:\=[^;]*)?;\s*/);
            for (var nLen = aKeys.length, nIdx = 0; nIdx < nLen; nIdx++) { aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]); }
            return aKeys;
        }
    };

    var __cache = {};
    var memoryStorage = {
        getItem:function(key) {
            if(!key) return null;
            return _.get(__cache, key);
        },
        setItem:function(key, value) {
            if(!key || !value) return null;
            _.set(__cache, key, value);
            return value;
        },
        removeItem:function(key) {
            if(!key) return null;
            return _.unset(__cache, key);
        }
    };

    function storageAvailable(type) {
        try {
            var storage = window[type],
                x = '__storage_test__';
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        }
        catch(e) {
            return false;
        }
    }

    function supportsStorageType(type) {
        try {
            var test = "_";
            type.setItem(test, test);
            var supports = type.getItem(test) === test;
            type.removeItem(test);
            return supports;
        } catch (e) {
            return false;
        }
    }

    var supportsCookieStorage = navigator.cookieEnabled !== false && supportsStorageType(docCookies);
    var supportsLocalStorage =  storageAvailable("localStorage") && supportsStorageType(window.localStorage);
    var supportsSessionStorage = storageAvailable("sessionStorage") && supportsStorageType(sessionStorage);

    var session = {
        //__key:storageKey,
        __cached:{},
        __mechanisms:_.filter([ 
            supportsSessionStorage ? sessionStorage : null,
            supportsCookieStorage ? docCookies: null,
            memoryStorage,
        ]),
    };
    session.remove = function(key) {
        _.unset(session.__cached, key);
        _.each(session.__mechanisms, function(store) {
            if(!store) return;
            try {
                store.removeItem(key);
            } catch(e) {

            }
        });
    };
    session.set = function(key, value) {
        if(!value) return session.removeItem(key);

        _.set(session.__cached, key, value);

        // try to set the first avail storage
        return _.find(session.__mechanisms, function(store) {
            if(!store) return null;
            try {
                store.setItem(key, JSON.stringify(value));
                return value;
            } catch(e) {
                return null;
            }
        });
    };
    session.get = function(key) {
        return _.get(session.__cached, key) || _.reduce(session.__mechanisms, function(result, store) {
            if(!!result || !store) return result;
            //if(!store) return result;
            try {
                //console.log("store.getItem", store, key);
                var value = store.getItem(key);
                //console.log("store.getItem.value", value, key);
                if(!!value) return JSON.parse(value);
                return null;
            } catch(e) {
                return null;
            }
        }, null);
    };

    exports.Session = session;


    var storage = {
        //__key:storageKey,
        __cached:{},
        __mechanisms:_.filter([ 
            supportsLocalStorage ? window.localStorage : null,
            supportsCookieStorage ? docCookies: null,
            memoryStorage,
        ]),
    };
    storage.remove = function(key, domain) {
        _.unset(storage.__cached, key);
        _.each(storage.__mechanisms, function(store) {
            if(!store) return;
            try {
                store.removeItem(key);
            } catch(e) {

            }
        });
        if(supportsCookieStorage && !!domain) docCookies.removeItem(key, null, domain);
    };
    storage.set = function(key, value, domain, expires) {
        if(!value) return storage.removeItem(key);

        _.set(storage.__cached, key, value);

        // try to set the first avail storage
        var setValue = _.find(storage.__mechanisms, function(store) {
            if(!store) return null;
            try {
                store.setItem(key, JSON.stringify(value));
                return value;
            } catch(e) {
                return null;
            }
        });

        if(!!domain && supportsCookieStorage) {

            docCookies.setItem(key, JSON.stringify(value), expires, "/", domain, true);

        }

        return setValue;
    };
    storage.get = function(key) {
        return _.get(storage.__cached, key) || _.reduce(storage.__mechanisms, function(result, store) {
            if(!!result || !store) return result;
            //if(!store) return result;
            try {
                //console.log("store.getItem", store, key);
                var value = store.getItem(key);
                //console.log("store.getItem.value", value, key);
                if(!!value) return JSON.parse(value);
                return null;
            } catch(e) {
                return null;
            }
        }, null);
    };

    exports.Storage = storage;

    // durable storage?

}(ParkIQ.API));

// @codekit-prepend "../node_modules/shared.static/scripts/js/api.auth.js";
(function(exports, storage, jwt_decode) {

    var api = exports;
    storage = storage || api.Storage;

    var auth = {};

    // this could use some additional jwt mechanics

    function keys(scope) {
        if(!scope) return [ "user/auth" ];
        return [
            //"user/auth/" + scope,
            "user/auth",
        ];
    }

    function get(scope, checkExpired) {

        var data = _.find(_.map(keys(scope), function(key) {
            
            var data = storage.get(key);
            if(!data || !data.token || !jwt_decode || !checkExpired) return data;

            try {
                jwt = jwt_decode(data.token);
                if(!jwt || !jwt.exp) return data;

                if(jwt.exp * 1000 < Date.now()) return null; // expiration is past
                
            } catch(error) {
                return data;
            }

            return data;

        }));

        return data;

    };

   function promise(scope, checkExpired) {
        return Promise.resolve(get(scope, checkExpired))
        .then(function(data) {
            if(!data || !data.token || !data.type) return Promise.reject(_.extend(new Error("No auth data"), { status: 401 }));
            return data;
            
        });
    };

    function header(scope, checkExpired) {
        return promise(scope, false !== checkExpired)
        .then(function(data) {
            return data.type + " " + data.token;
        });
    };

    function set(data, scope, domain, expires) {
        _.each(keys(scope), function(key) {
            storage.set(key, data, domain, expires);
        });
    };

    function remove(scope, domain) {
        _.each(keys(scope), function(key) {
            storage.remove(key, domain);
        });
    };

    _.extend(auth, {
        get: get,
        set: set,
        remove: remove,
        promise: promise,
        header: header,
    });

    exports.Auth = auth;

}(ParkIQ.API, ParkIQ.API.Storage, jwt_decode));

// @codekit-prepend "../node_modules/shared.static/scripts/js/api.auth.context.js";
// (function(auth, page, qs, jwtdecode) {

//     function authFromUrl(path, query, hash) {


//         if(typeof query === "string") query = qs.parse(query);

//         if(typeof hash === "string") hash = qs.parse(hash);

//         var strip = [];

//         var source = _.defaults({}, hash, query);

//         var token = _.get(source, "access_token");
//         if(!!token) strip.push("access_token");

//         if(!token) {
//             token = _.get(source, "token");
//             if(!!token) strip.push("token");
//         }

//         if(!token) return null;

//         var extra = [ "lifetime", "expires", "scope", "user" ];
//         Array.prototype.push.apply(strip, extra);

//         var data = _.assign({
//             token: token,
//             type: "bearer",
//         }, _.pick(extra));

//         if(!!data) {

//             _.each(strip, function(key) {
//                 _.unset(query, key);
//                 _.unset(hash, key);
//             });
//             var newQuery = qs.stringify(query);
//             var newHash = qs.stringify(hash);
//             window.history.replaceState(null, null, path + (!!newQuery ? "?" : "") + newQuery + (!!newHash ? "#" : "") + newHash);

//         }

//         if(!!data && !!jwtdecode) {

//             var jwt = null;
//             try {
//                 jwt = jwtdecode(data.token);
//             } catch(e) {}

//             if(!!jwt) {

//                 if(!!jwt.exp) {
//                     _.set(data, "expires", new Date(jwt.exp * 1000).toISOString());
//                     _.unset(jwt, "exp");
//                 }

//                 if(!!jwt.sub) {
//                     _.set(data, "user", jwt.sub);
//                     _.unset(jwt, "exp");
//                 }

//                 // copy the rest
//                 _.assign(data, jwt);
//             }

//         }

//         return data;

//     }

//     var data = authFromUrl(location.pathname, (location.search || "?").substr(1), (location.hash || "#").substr(1));
//     if(!!data) auth.set(data, data.user);

//     // this will have rewritten the url already, never picked up by the page module

//     function authFromContext(ctx, next) {

//         var auth = authFromUrl(location.pathname, (location.search || "?").substr(1), (location.hash || "#").substr(1));

//         if(!!auth) {
//             auth.user = _.get(ctx, "params.user") || auth.user;
//             api.Auth.set(auth, auth.user);
//             console.log("auth", auth);
//             ctx.auth = auth;
//         }

//         next();
//     };



// }(ParkIQ.API.Auth, page, qs, jwt_decode));

// @codekit-prepend "_api.fetch.js";
(function(api) {
    
    function json(response) {
        if(!response) return {};
        return response.text()
        .then(function(text) {

            if(!text) return {
                status: response.status
            };

            return Promise.resolve(text)
            .then(JSON.parse)
            .catch(function(error) {
                return {
                    status: response.status,
                    message: text
                };
            });

        })
        .catch(function(error) {
            return {
                status: response.status
            };
        });
    }

    api.fetch = function(method, url, body, auth) {

        console.log("api.fetch", ...arguments);

        // simple process
        //var requested = new Date().toISOString();
        return Promise.resolve(auth === true ? api.Auth.header() : auth === false ? null : auth) // we want to catch unauthorized auth issues
        .then(function(auth){

            return Promise.all([
                method,
                url,
                body, 
                auth,
            ]).then(function([ method, url, body, auth ]) {

                var options = {};
                if(!!method) _.set(options, "method", method);
                //if(!!auth) _.set(options, "headers.Authorization", auth);
                if(!!auth) url = url + (url.indexOf("?") >= 0 ? "&" : "?") + "Authorization=" + auth;
                if(!!body) _.set(options, "body", body);

                return fetch(url, options);
                    
            });
            

        })
        .then(function(response) {
            //if(!!response.ok) return response.json();
            if(!!response.ok) return json(response);

            var e = _.assign(new Error(response.status+""), {
                name:response.status+"",
                status:response.status,
                response: response,
            });

            //_.extend(e, response);
            //_.set(e, "response", response);

            // return response.json()
            // .catch(function(e) {
            //     return null;
            // })
            return json(response)
            .then(function(json) {
                //console.log("error json", e, json);
                if(!!json) _.set(e, "data", json);
                _.set(e, "message", _.get(json, "message") || e.message); // update message
                //console.log("error data", _.get(e, "data"));
                return Promise.reject(e);
            });
        })
        // .then(function(response) {
        //     if(!!response.ok) return response.json();

        //     var e = new Error(response.status);
        //     _.extend(e, response);
        //     _.set(e, "response", response);

        //     return response.json()
        //     .catch(function(e) {
        //         return null;
        //     })
        //     .then(function(json) {
        //         if(!!json) e.data = json;
        //         return Promise.reject(e);
        //     });
        // })
        //.then(api.response.entities)
        .catch(function(error) { // this is to catch a bug in Edge fetch
            //console.log("catching");
            //console.log("typemismatcherror", error);

            if(_.get(error, "name", error) === "TypeMismatchError") return Promise.reject(_.assign(new Error("401"), {
                name:"401",
                status:401,
            }));
            return Promise.reject(error);
            
        });
        // .catch(function(error) {
        //     if(!!error && error.status == 401) {
        //         document.documentElement.dispatchEvent(new CustomEvent("unauthorized", { bubbles: true }));
        //         return Promise.resolve({ status: error.status });
        //     }
        //     return Promise.reject(error);
        // });

    };

}(ParkIQ.API));

// @codekit-prepend "../node_modules/shared.static/scripts/js/api.idkeyvaluesearcher.js";
//var ParkIQ = window.ParkIQ = ParkIQ || {};

//ParkIQ.API = ParkIQ.API || {};

(function(exports) {
	
	var normalize = function(val, relaxed) {
        //console.log("val", val);
        //console.trace();

        val = val || "";
        if(!val) return val;
		//if(!!relaxed) return val.toUpperCase().replace(/[^A-Z0-9\s]/gi, "");
		return val.toUpperCase().replace(/[^A-Z0-9]/gi, "").replace("O", "0");
        //return val;
	};
	
	exports.IDKeyValueSearcher = function(relaxed, numericSort) {

        
        
        var sorter = function(a,b) {
						
            var x = a.value;
            var y = b.value;

            // first si
            
            if(!numericSort) return x < y ? -1 : x > y ? 1 : 0; // string sort
            
            if(x.length == y.length) return x < y ? -1 : x > y ? 1 : 0; // numeric type
            else if (x.length < y.length) return -1;
            else return 1;
        };

        var sort = function(arr) {

                //console.log(arr);
                //if(!numericSort) return _.sortBy(arr, [ "index", "value" ]);
                //if(!numericSort) return _.orderBy(arr, [ "score", "index", "value" ], [ "desc", "asc", "asc"]);
                if(!numericSort) return _.orderBy(arr, [ "score", "value" ], [ "desc", "asc"]);
    
                return arr.sort(sorter);
    
            };

        var _lunr = function(data) {
            return Promise.resolve(lunr(function() {
                this.field("key");
                this.field("value");
                this.ref("id");

                //console.log("lunr data", data);

                data.forEach(function(item) {
                    this.add(item);
                }, this);

                this.pipeline.remove(lunr.stopWordFilter); // no stop word filter
                this.pipeline.remove(lunr.stemmer); // no stemmer
            }))
        }
		
		var searcher = {
            // _lunr:Promise.resolve(lunr(function () {
            //     this.field("value");
            //     this.ref("id");

            //     this.pipeline.remove(lunr.Pipeline.registeredFunctions['stopWordFilter']); // no stop word filter
            // })),
            //_lunr:_lunr([]),
		};
        
        var all = {
            cache: {},
            result: Promise.resolve({
                query:"",
                results:[],
            }),
        };
        
        searcher.isValid = function(idKeyValue) {
            
            if(!idKeyValue) return true;
            if(!all.cache) return true;
            if(_.isEmpty(all.cache)) return true;
            
            //console.log("idKeyValue", idKeyValue);
            //console.log(idKeyValue.toUpperCase().replace(/\W+/g, ""));
            
            return !!_.find(all.cache, function(item) {
                //console.debug(item);
                return item.id === idKeyValue || (item.key || "") === normalize(idKeyValue, relaxed);
            });

            //return true;
        };
		
		searcher.add = function(itemOrCollection) {

            //console.log(itemOrCollection);
            //console.trace();
            
			var data = !!itemOrCollection.id && !!itemOrCollection.key ? [ itemOrCollection ] : _.map(itemOrCollection, function(item, key) {
                if(!item) return;

                // this isn't an indexable item
                if(!item.id && !item.key && !item.value && !!key) return {
					id:key + "",
                    key:key + "",
					value:item + "",	
				}; 

                if(!!item.id && !!item.key && !!item.value) return item;

                /*
                if(!!item.id && !item.key) return null; // id with no key isn't searchable
                if((!!item.id || !!item.key) && !item.value) return null; // 
                if(!!item.id && !!item.key) return item;

                //handle ? { id: "TF7rwdfYXkWuhJn27O5w6g", key: "", value: "" }
                // {id: "", key: "", value: "*"}

                if(!key) return null;
				
				// we be prepared to handle string-string maps
				return {
					id:key,
                    key:key,
					value:item,	
				};
                */

                return null;
			});
            
            //console.debug(data);
            
            _.each(data, function(item) {
                if(!item) return;
                if(!!item.key) item.key = normalize(item.key, relaxed);
                all.cache[item.id] = item;
            });
            
            //console.log(all.map);
            
            // resort
            all.result = Promise.resolve({
                query:"",
                results:_.map(all.cache).sort(sorter),
            });

            // rebuild our lunr
            //searcher._lunr = _lunr(_.values(all.cache));
            
            // searcher._lunr.then(function(index) {
            //     //console.log(data);
            //     _.each(data, function(item) {
            //         //console.log(item);
            //         if(!item) return;

            //         var newItem = _.clone(item);
            //         newItem.value = normalize(newItem.value, relaxed); // normalize value before adding
            //         index.add(newItem);
            //         //console.log(newItem);
            //     });
            // });
			
		};
		
		searcher.search = function(query, allIfEmpty) {
			
			query = normalize(query, relaxed);
			//console.log("normalized query = " + query);			
			// we need engine
            //console.log(all);

            //console.log(query);

            //allIfEmpty = false; // no more support...
            if(!query && !!allIfEmpty) return all.result;
            
            return all.result
            .then(function(all) {

                //console.log(all);

                if(query.length <= 0) return []; // no results

                //var results = [];

                return _.reduce(all.results, function(results, item) {

                    var score = 0 - item.key.toUpperCase().indexOf(query);

                    if(score < 1) results.push(_.extend({
                        score: score,
                    }, item)); // contains

                    return results;
                    
                }, []);

            })
        
            //return searcher._lunr
            // .then(function(index) {
            //     //console.log(index);
            //     if(query.length <= 0) return []; // no results

            //     // return index.query(function(q) {
            //     //     // build the query, skip text parsing

            //     //     //exact matches should have the highest boost
            //     //     q.term(query, { boost: 100, usePipeline: true });
                    
            //     //     // prefix matches should be boosted slightly
            //     //     q.term(query, { boost: 10, usePipeline: true, wildcard: lunr.Query.wildcard.TRAILING });

            //     //     // prefix matches should be boosted slightly
            //     //     q.term(query, { boost: 2, usePipeline: true, wildcard: lunr.Query.wildcard.LEADING | lunr.Query.wildcard.TRAILING });
                    
            //     //     // finally, try a fuzzy search, without any boost
            //     //     if(query.length > 2) q.term(query, { boost: 1, usePipeline: true, editDistance: 1 });
                    
            //     //     //q.term(query, { wildcard: lunr.Query.wildcard.LEADING | lunr.Query.wildcard.TRAILING });
            //     //     console.log(q);
            //     // });

            //     return index.search("*" + query + "*"); // mid-word matches
            //     // console.log("r", r);
            //     // return r;
            // })
            // .then(function(results) {
            //     //console.log("results", results);
            //     // map the source onto results, sort by value
            //     // these are pre-sorted by score desc
            //     return _.map(results, function(item) {
            //         //console.log(item);
            //         return _.extend({
            //             //index:item.
            //             // custom scorer based on index
            //             score:1 - _.min(_.map(item.matchData.metadata, function(data, term) {
            //                 return term.toUpperCase().indexOf(query);
            //             })),
            //             //score:item.score,
            //         }, all.cache[item.ref]);
            //     });
                
            // })
            .then(function(results) {
                //console.debug(results);
                return {
                    query:query,
                    results:sort(results),
                };
            });
			
		};
		
		return searcher;
		
	};
		
})(ParkIQ.API);

// @codekit-prepend "../node_modules/shared.static/scripts/js/api.locations.js";
(function(api,postal) {
	
	var store = api.Locations = {
        predefined: true,
	};
	
	// allocate a search engine
	var _lookup = store.Lookup = api.IDKeyValueSearcher(true, false);
	
	_.extend(store, {
        isValid:_lookup.isValid,
		lookup:function(query, omitNoMatchItem, allIfEmpty) {
            
            if(omitNoMatchItem !== true && omitNoMatchItem !== false) omitNoMatchItem = store.predefined;
            if(allIfEmpty !== true && allIfEmpty !== false) allIfEmpty = store.predefined;
			
			query = query || "";
            if(!!query) query = query.toUpperCase();
			
			
			// we need engine
			return _lookup.search(query, allIfEmpty).then(function(result) {
				
				//console.log(results);
                
                var normalized = result.query;
                var results = result.results;
                
                //console.log(results);
						
				if(!omitNoMatchItem && (query != null && query.length >= 1) && (results.length == 0 || !_.find(results, ['value', query]))) results.push({
					id:normalized,
					key:normalized,
					value:query,
				}); // prefill query if no match so far
				
				//var now = Date.now();
				
				var items = _.reduce(results, function(items, item) { // make sure plates unique
					
					if(item.value == query) items.unshift({
						id:item.id,
						key:item.key,
						display:item.value,
                        url:item.url,
					});
					else items.push({
						id:item.id,
						key:item.key,
						display:item.value,
                        url:item.url,
					});
					
					return items;
					
				}, []);
				
				
				
				return {
					query: query,
                    normalized:normalized,
					items: items,
                    updated: result.updated,
				};
						

			});
			
		},
		add:store.Lookup.add,
	});
	
	var last = null;

	function onTopic(data, envelope) {
				
		var ts = new Date(data.generated || data.ts);
		
		if(!!last && last.getTime() >= ts.getTime()) return; // this isn't newer
		
		store.add(_.reduce(data.items, function(list, item, key) {
			
			list.push({
				id:item.id,
				key:item.name,
				value:item.name,
				url:item.admin,
				//display:item.display,
			});
			

			return list;

		}, []), data.generated);

		//last = ts;
	};
	
	_.each([
		"locations.items",
		//"locations.items.updated",
	], function(topic) {
		postal.subscribe({
			topic:topic,
			callback:onTopic,
		});
	});
	
})(ParkIQ.API, postal);

// @codekit-prepend "../node_modules/shared.static/scripts/js/api.items.js";
(function(api) {
	
	// is the date "today" in local system time
	function today(datetime) {
		var now = new Date();
		//console.log(now);
		var test = new Date(datetime); // eat anything date-y
		//console.log(test);
		return now.getFullYear() == test.getFullYear() && now.getMonth() == test.getMonth() && now.getDate() == test.getDate();
	}
	
	function fromNow(datetime, nosuffix) {
		return moment(datetime).fromNow(nosuffix);
	}
	function calendar(datetime) {
		return moment(datetime).calendar();
	}
	function format(datetime, format) {
		return moment(datetime).format(format);
	}
	
	function setFromCollection(item, prop, collection, srcprop) {
		if(!item) return item;
		var val = _.get(item, srcprop || prop);
		if(!val) return item;
		if(!val.id && collection) {
			// find new val by map to self or find an item with a matching "key" prop
			var newVal = _.get(collection, val);
			if(!!newVal) _.set(item, prop, newVal);
		}
		return item;
	}
	_.extend(api.Items = api.Items || {}, {
        get:function(data, name) {
			var item = _.get(data, [name, "item"]);
			return _.get(data, [ name, "items", item], item);
		},
		vehicle:function(item, collection, srckey) {
			return setFromCollection(item, "vehicle", collection, srckey);
		},
		tenant:function(item, collection, srckey) {
			return setFromCollection(item, "tenant", collection, srckey);
		},
		space:function(item, collection, srckey) {
			return setFromCollection(item, "space", collection, srckey);
		},
		media:function(item, collection, srckey) {
			return setFromCollection(item, "media", collection, srckey);
		},
		/*notes:function(item, collection) {
			if(!!collection && !item.notes) item.notes = _.map(collection[item.id]);
			return item;
		},*/
		notes:function(item, collection) {
			if(!!collection && !item.notes) {
				var target = (collection.containers || collection)[item.id];
				if(!!target) item.notes = _.map(target.items || target);
			}
			return item;
		},
        files:function(item, collection) {
			if(!!collection && !item.files) {
				var target = (collection.containers || collection)[item.id];
				if(!!target) item.files = _.map(target.items || target);
			}
			return item;
		},
		sent:function(item, collection) {
			if(!!collection && !item.sent) {
				var target = (collection.containers || collection)[item.id];
				if(!!target) item.sent = _.map(target.items || target);
			}
			return item;
		},
		user:function(item, collection) {
			return setFromCollection(item, "user", collection);
		},
		expandDates:function(item, props) {
			if(!item) return item;
			
			props = props || [ "created", "updated", "active", "issued", "valid.to", "valid.from", "grace.to", "grace.from"];
				
			_.each(props, function(prop) {
				var val = _.get(item, prop);
				if(!val) return;
				if(_.isString(val)) {
					val = { utc: val, };
					_.set(item, prop, val);
				}
				// set properties
				if(!!val.utc) {
					val.today = today.bind(val.utc, val.utc);
					val.relative = fromNow.bind(val.utc, val.utc);
					//val.display = moment(val.utc).calendar();
					val.calendar = calendar.bind(val.utc, val.utc);
					val.format = format.bind(val.utc, val.utc);
				}
			});
			
			return item;
		},
		normalize:function(item) {
			if(!item) return item;
			api.Items.expandDates(item);
			//console.log(item);
			_.each([ "issued.user", "updated.user"], function(prop) {
				var user = _.get(item, prop);
				//console.log(user);
				if(!user) return;
				if(!user.name && !user.email) return;
				user.name = user.display = user.name || user.email.split("@")[0];
			});
			return item;
		},
		duration:function(item, name) {
			
			var val = !!name ? (_.get(item, [ name, "iso" ]) || _.get(item, [ name, "duration" ]) || _.get(item, [ name, "time" ]) || _.get(item, name, item)) : item;

			if(!val) return null;
			if(!_.isString(val)) return null; // we only process strings...
			var dur = moment.duration(val);

			var newVal = {
				iso:dur.toJSON(),
				zero: dur.asMilliseconds() == 0,
				//value:val,
				display:(dur.asHours() <= 72 ? Math.ceil(dur.asHours()) + " hour" + (dur.asHours() == 1 ? "" : "s") : dur.format(dur.hours() == 0 ? dur.days() == 1 ? "d[ day]" : "d[ days]" : "d[d], h[h]")),
			};

			if(!!name && val != item) item[name] = newVal;

			//console.log(newVal);

			return newVal;
		
		},
	});
	
})(ParkIQ.API);

// @codekit-prepend "../node_modules/shared.static/scripts/js/api.templates.js";
export default ParkIQ;