import { router as page } from "../util/router";
import { qs } from "@parkingboss/utils";
import { isBefore } from "date-fns";

import ParkIQ from "./init";
import postal from "postal";
//import Promise from "bluebird";
import * as _ from "lodash-es";

window.postal = postal;

if (!window.CSS || !window.CSS.supports || !CSS.supports("display", "grid"))
  throw new Error("Unsupported Client");

// window.addEventListener("message", function(e) {
//     console.log(e);
// });

var googleMapsInit = new Promise(function (resolve) {
  window._initGoogleMaps = function () {
    resolve(google.maps);
  };

  var script = _.assign(document.createElement("script"), {
    src: "https://maps.googleapis.com/maps/api/js?key=AIzaSyBaDZIV8UHKyZpzRV05VD8NTmyXIQnpZC8&callback=_initGoogleMaps",
    defer: true,
    async: true,
  });

  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(script, s);
});

var googleChartsInit = new Promise(function (resolve) {
  var script = _.assign(document.createElement("script"), {
    src: "https://www.gstatic.com/charts/loader.js",
    defer: true,
    async: true,
  });

  script.onload = function (e) {
    google.charts.load("current", { packages: ["timeline"] });
    google.charts.setOnLoadCallback(function () {
      resolve(google.charts);
    });
  };

  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(script, s);
});

// url
var APPNAVIGATEURL = null;

(function (page) {
  page("*", function (ctx, next) {
    if (!!ctx.query.url) APPNAVIGATEURL = ctx.query.url;

    if (!!next) next();
  });
})(page);

// location
(function (page, qs, api, root) {
  function extractFromQuery(path, query) {
    //console.log("extractFromQuery", query, api.location)

    if (typeof query === "string") query = qs.parse(query);

    if (
      _.some(["location", "l"], function (key) {
        var value = _.get(query, key);
        if (!value) return false;

        api.location = value;
        //console.log("api.location", api.location);
        _.unset(query, key); // remove
        return true;
      })
    ) {
      var newQuery = qs.stringify(query);
      window.history.replaceState(
        null,
        null,
        path + (!!newQuery ? "?" : "") + newQuery
      );
    }
  }

  // extract on load
  extractFromQuery(
    window.location.pathname,
    qs.parse((window.location.search || "?").substr(1))
  );

  // extract on each page
  page("*", function (ctx, next) {
    extractFromQuery(ctx.pathname, qs.parse(ctx.querystring));
    next();
  });

  // take in user and url and send them with auth token
  function navigateAuthenticated(user, url) {
    if (!url) return false;

    var auth = api.Auth.get(user, true);

    //console.log("navigateAuthenticated", user, auth);

    if (!auth) return page("/login"); // logged out

    // validate expiry?
    if (!!auth.expires && isBefore(auth.expires, new Date()))
      return page("/login"); // logged out

    var a = document.createElement("a");
    a.href = url;

    //if(_.startsWith(a.hostname, "frontdesk.")) return window.location.href = url; // no url token

    //console.log("url", url, url.replace("reports.parkingboss.com", "reports.parkingboss.app"));

    //a.href = url = url.replace("reports.parkingboss.com", "reports.parkingboss.app").replace("manager.parkingboss.com", "manager.parkingboss.app").replace("fieldagent.parkingboss.com", "fieldagent.parkingboss.app");

    // only add if auth + whitelisted domain...don't leak token to unapproved domains
    if (
      !!auth &&
      (!!_.find(
        [
          ".local",
          "localhost",
          ".parkingboss.com",
          ".parkingboss.app",
          ".parkingboss.dev",
          ".amenityboss.com",
          ".communityboss.app",
        ],
        function (host) {
          return (
            _.endsWith(a.hostname, host) ||
            _.startsWith(
              a.href,
              "https://s3.amazonaws.com/components.parkingboss.com/"
            )
          );
        }
      ) ||
        !!_.find(["parkingboss.app", "parkingboss.dev"], function (host) {
          return _.isEqual(a.hostname, host);
        }))
    ) {
      _.find(
        {
          //"manager.parkingboss.com":"manager.parkingboss.app",
          //"fieldagent.parkingboss.com":"fieldagent.parkingboss.app",
          "reports.parkingboss.com": "reports.parkingboss.app",
        },
        function (to, from) {
          if (!a.hostname.startsWith(from)) return false;
          a.href = url = url.replace(from, to);
          return true;
        }
      );

      if (
        [
          //"fieldagent.parkingboss.dev",
          "fieldagent.parkingboss.app",
          "fieldagent.parkingboss.com",
          //"manager.parkingboss.dev",
          "manager.parkingboss.com",
          "manager.parkingboss.app",
        ].includes(a.hostname)
      ) {
        // query token
        url +=
          (_.includes(url, "?") ? "&" : "?") + "access_token=" + auth.token; // add query token
      }
      //else {
      url = url.split("#")[0] + "#access_token=" + auth.token; // hash token rewrite
      //}
    }

    console.log("navigating", url);

    //return page.redirect(url);
    return (window.location.href = url);
  }

  // navigate a logged-in user to the url
  page("/users/:user/navigate", function (ctx, next) {
    //console.log(ctx, window.location.search, Qs.parse(window.location.search));

    var url = ctx.query.url || APPNAVIGATEURL;
    if (!url) return;

    //console.log("url to navigate to", ctx.querystring);

    if (!navigateAuthenticated(ctx.params.user, url)) return;

    root.classList.add("outbound");
  });

  page("/user/navigate", function (ctx, next) {
    var url = ctx.query.url || APPNAVIGATEURL;
    if (!url) return;

    if (!navigateAuthenticated(null, url)) return;

    root.classList.add("outbound");
  });
})(page, qs, ParkIQ.API, document.documentElement);

// reset
(function (page, api, root, container) {
  function enter() {
    root.setAttribute("data-record", container.getAttribute("data-record"));
  }

  function exit(ctx, next) {
    next();
  }

  [
    "/login/password",
    "/login/reset",
    "/login/email",
    "/login/forgot",
    "/login/link",
  ].forEach(function (path) {
    page(path, enter);
    page.exit(path, exit);
  });

  var form = container.querySelector("form");
  form.setAttribute("novalidate", "novalidate");

  form.addEventListener("submit", function (e) {
    e.preventDefault();

    //console.log("form.submit");

    var form = this;

    // make sure only name because otherwise will grab in-process file inputs
    var disable = _.filter(
      form.querySelectorAll("button,input,textarea,select"),
      function (input) {
        input.blur();
        return !input.disabled && input.type !== "hidden";
      }
    );

    if (!!form.matches(":invalid")) return;
    if (
      disable.filter(function (input) {
        return !!input.required && !input.value;
      }).length > 0
    )
      return; // required with no value

    if (form.classList.contains("submitting")) return;

    _.each(["data-error", "data-success"], function (attr) {
      form.removeAttribute(attr);
    });
    form.classList.add("submitting");
    form.setAttribute("data-submitting", "true");

    var formData = new FormData(form);

    // disable
    disable.forEach(function (input) {
      input.disabled = true;
    });

    var requested = new Date().toISOString();

    Promise.all([api.base()])
      .then(function ([base]) {
        return api.fetch(
          "POST",
          base + "v1/auth/tokens/email?ts=" + requested,
          formData
        );
      })
      .then(function (json) {
        json.requested = requested;
        return json;
      })
      .then(function () {
        //form.reset();
        // show ok

        form.setAttribute(
          "data-success",
          "Great, check your inbox for a login email"
        );
      })
      .catch(function (error) {
        //alert("Whoops, something went wrong");

        console.log(error);

        switch (error.status) {
          case 400:
            form.setAttribute("data-error", "Whoops, this isn't a valid email"); // bad request
            break;
          case 404:
            form.setAttribute(
              "data-error",
              "Whoops, we couldn't find a user with this email"
            ); // bad request
            break;
          default:
            form.setAttribute(
              "data-error",
              "Whoops, something went wrong, please try again"
            );
            break;
        }
      })
      .finally(function () {
        form.classList.remove("submitting");
        form.removeAttribute("data-submitting");
        disable.forEach(function (input) {
          input.disabled = false;
        });
      });
  });

  form.addEventListener("reset", function (e) {});
})(
  page,
  ParkIQ.API,
  document.documentElement,
  document.querySelector("main[data-record='user/authentication/email']")
);

// user
(function (page, api, root, container, postal, appInsights) {
  var user;

  var state = {};

  var form = container.querySelector("form[data-record='user']");
  var userMenu = root.querySelector("body > nav ul");
  var bossMenu = root.querySelector("body > nav ul + ul");

  var template = _.template(
    '<fieldset><% if(!!_.get(item, "name.request")) { %><label><span>Set your name</span><input name="name" required="required" type="text" value="<%= _.get(item, "name.value", "") %>" autocomplete="name" placeholder="Set your name"></label><% } %><% if(!!_.get(item, "email.request")) { %><label><span>Set your email</span><input name="email" required="required" type="email" value="<%= _.get(item, "email.value", "") %>" autocomplete="new-email" placeholder="Set your email"></label><% } %><% if(!!_.get(item, "password.request")) { %><label class="<%= _.filter([ "password", !!_.get(item, "password.required") ? "required" : "" ]).join(" ") %>"><span><%= !!_.get(item, "password.required") ? "Set your password" : "Change your password" %></span><input name="password" type="password" autocomplete="new-password" <%= !!_.get(item, "password.required") ? "required" : "" %> placeholder="<%= "Enter your new password" %>"><input type="password" autocomplete="new-password" <%= !!_.get(item, "password.required") ? "required" : "" %> placeholder="Confirm your new password"></label><% } %></fieldset><button type="submit">Save & Continue</button>',
    {
      variable: "item",
    }
  );

  // var boss = '<% if(_.get(item, "admin")) { %><!--<li><a href="/users/<%= _.get(item, "id") %>/violations">Violations</a></li><li><a href="/users/<%= _.get(item, "id") %>/sessions">Activity</a></li>--><li><a href="/users/<%= _.get(item, "id") %>/locations/new">New Location</a></li><li><a href="/users/<%= _.get(item, "id") %>/health">Health</a></li><li><a href="/users/<%= _.get(item, "id") %>/navigate?url=https://boss.parkingboss.com/files/photos">Photos</a></li><% } %><li><a href="https://store.parkingboss.com/?utm_source=my.parkingboss.com">Order Materials</a></li>';

  var userMenuHtml = _.template(
    '<li><a href="/users/<%= _.get(item, "id") %>/locations">All Locations</a></li><li><a href="/users/<%= _.get(item, "id") %>/edit">User Info</a></li><li><a href="/users/<%= _.get(item, "id") %>/navigate?url=https://auth.communityboss.app/login/change?redirect_uri=<%= redirect_uri %>">Change Password</a></li>',
    {
      variable: "item",
      imports: {
        redirect_uri: location.href,
      }
    }
  );

  // var bossMenuHtml = _.template(boss, {
  //     variable:"item",
  // });

  function header(user) {
    //console.log("header", user);
    document.title = _.filter([_.get(user, "name"), "Community Boss"]).join(
      " - "
    );
    _.set(
      root.querySelector("body > header"),
      "innerHTML",
      "<h1>" +
        _.get(user, "name", "") +
        '</h1></h2><a href="mailto:' +
        _.get(user, "email", "") +
        '">' +
        _.get(user, "email", "") +
        "</a></h2>"
    );
  }

  function nav(user) {
    _.set(userMenu, "innerHTML", !user ? "" : userMenuHtml(user));
    _.set(
      bossMenu,
      "innerHTML",
      '<li><a href="https://store.parkingboss.com/?utm_source=my.parkingboss.com">Order Materials</a></li>'
    );

    //_.set(bossMenu, "innerHTML", !user ? "" : bossMenuHtml(user));
  }

  function edit(user, cancellable, fields) {
    if (!user) return;

    document.title = _.get(user, "name", "Account") + " - Community Boss";

    root.querySelector("form[data-record='user/setup']").innerHTML = template(
      _.defaultsDeep(fields || {}, {
        name: {
          request: true,
          required: true,
          value: _.get(user, "name"),
        },
        email: {
          request: true,
          required: true,
          value: _.get(user, "email"),
        },
        password: {
          request: true,
          required: _.get(user, "password.change"),
        },
      })
    );

    _.invoke(
      root.querySelector("form[data-record='user/setup'] + footer a"),
      !cancellable ? "removeAttribute" : "setAttribute",
      "href",
      "/users/" + _.get(user, "id")
    );

    root.setAttribute("data-record", "users/" + _.get(user, "id") + "/setup");
  }

  function record(json) {
    //console.log("record", json);

    return {
      requested: json.requested,
      generated: json.generated,
      user:
        _.get(json, ["items", _.get(json, "users.item")]) ||
        _.get(json, ["users", "items", _.get(json, "users.item")]) ||
        _.get(json, "users.item"),
    };
  }

  function authenticated(user, location) {
    console.log("authenticated", user, location);

    return Promise.resolve(user)
      .then(function (user) {
        if (!user) return Promise.reject(new Error("No user"));
        return user;
      })
      .then(function (user) {
        //console.log("user", user);
        return api.Auth.promise(user, true);
        // .then(function(data) {
        //     console.log("auth data", data);
        //     return data;
        // }).catch(function(error) {
        //     console.log("error=", error);
        //     return null;
        // });
      })
      .then(function (auth) {
        if (!auth) return Promise.reject(new Error("No user session"));
        //if(!auth || !auth.user) return Promise.reject(new Error("No user session"));
        //onsole.log(json);
        return auth;
      })
      .then(function (auth) {
        var requested = new Date().toISOString();

        return Promise.all([
          api.base(),
          user,
          _.filter([auth.type, auth.token]).join(" "),
        ]).then(function ([base, user, authHeader]) {
          console.log(base, user, auth);

          var now = new Date();
          var requested = requested;

          return Promise.all([
            api
              .fetch(
                "GET",
                base + "v1/users/" + user + "?viewpoint=" + requested,
                null,
                authHeader
              )
              .then(function (json) {
                console.log("fetch json", json);

                // flagged as front desk or no locations
                // I don't think we need this anymore...
                // if(json.frontdesk === true || _.keys(_.get(json, "locations.items", {})).length <= 0) return api.fetch("GET", base + "users/" + user + "?ts=" + requested, null, authHeader)
                //     .then(function(v0json) {

                //         json.frontdesk = v0json.frontdesk;
                //         _.defaults(_.get(json, "locations.items"), _.get(v0json, "locations.items")); // update locations
                //         return json;

                //     });

                return json;
              }),
            // api.fetch("GET", base + "v1/locations?viewpoint=" + requested, null, authHeader)
            // .then(function(json) {
            //     return _.pick(json, [ "locations", "addresses" ]);
            // }),
            // api.fetch("GET", base + "v1/authorizations?principal=" + user + "&viewpoint=" + requested, null, authHeader)
            // .then(function(json) {
            //     return _.pick(json, [ "authorizations" ]); // all authorizations ever for this user...
            // }),
            // api.fetch("GET", base + "v1/authorizations?viewpoint=" + requested, null, authHeader)
            // .then(function(json) {
            //     return {}; // this gets all authorizations ever for any location user currently has access to
            //     return _.pick(json, [ "authorizations" ]); // all authorizations ever for this user...
            // }),
          ])
            .then(function (values) {
              return _.merge.apply(_, values);
            })
            .then(function (json) {
              _.set(state, user, json);

              //console.log("state", state);

              _.extend(json, {
                requested: requested,
                auth: auth,
              });
              if (!!location) _.set(json, "locations.item", location);

              //console.log("json loaded", json);

              return json;
            });
        });
      })
      .then(function (json) {
        //console.log("first", json);

        var user = _.get(json, "users.item");
        if (!!user && typeof user === "string")
          user = _.get(json, ["users", "items", user], user);

        if (!user) return Promise.reject(new Error("No user"));

        // log user...

        user.admin =
          user.admin ||
          !!_.get(json, "authorizations.system") ||
          !!_.get(json, "authorizations.admin");

        if (!!postal && !!user)
          _.each(
            [
              //"user",
              //"user.updated",
              "user.item",
            ],
            function (topic) {
              postal.publish({
                topic: topic,
                data: {
                  generated: json.generated,
                  requested: json.requested,
                  item: user,
                },
              });
            }
          );

        return json;
      })
      .catch(function (error) {
        //console.log("auth.error", error);
        return null; // is this just enough to kick off fail?
      });
    // .then(function(json) {

    //     var user = _.get(json, "users.item");
    //     if(!!user && typeof user === "string") user = _.get(json, [ "users", "items", user ], user);

    //     if(!user) return Promise.reject(new Error("No user"));

    //     //var url = _.get(json, [ "locations", "items", _.get(json, "locations.item"), "admin" ]);
    //     //if(!!url) url = url + "?access_token=" + _.get(json, "auth.token");

    //     //console.log(url);

    //     if(!!window.rg4js) {
    //         rg4js('setUser', {
    //             identifier: user.id,
    //             isAnonymous: false,
    //             email: user.email,
    //             fullName: user.name,
    //         });
    //         rg4js("trackEvent", {
    //             type: "pageView",
    //             path: window.location.pathname + window.location.search,
    //         });
    //     }

    //     //edit(user);

    //     return json;
    // });
  }

  function enter(ctx, next) {
    user = _.get(ctx, "params.user");
    //var single = !!_.get(ctx, "query.single");
    var forcePassword =
      !!_.get(ctx, "params.password") || _.endsWith(ctx.pathname, "/password");
    var forceEdit =
      !!_.get(ctx, "params.edit") || _.endsWith(ctx.pathname, "/edit");
    var forceSetup =
      !!_.get(ctx, "params.setup") || _.endsWith(ctx.pathname, "/setup");

    //console.log("single", single);

    console.log("enter=", ctx.user, user);

    return Promise.resolve(ctx.user || authenticated(user, api.location))
      .then(function (json) {
        var auth = api.Auth.get(user, true);
        console.log("enter auth=", auth, json);

        if (!auth) return page.show("/login");

        //console.log("enter json", json);

        //var frontdesk = _.get(json, "frontdesk");
        var token = _.get(json, "auth.token");

        // legacy token, remove for new field agent
        // if(!!token) {

        //     // this doesn't work in safari - blocked
        //     [ "smartdecal.parkingboss.com", "smartpermit.app" ].forEach(function(domain) {
        //         const iframe = document.createElement("iframe");
        //         iframe.setAttribute("style","position: absolute !important; opacity: 0 !important; width: 1px !important; height: 1px !important; top: 0 !important; left: 0 !important; border: none !important; display: block !important; z-index: -1 !important;");
        //         iframe.addEventListener("load", function() {
        //             //iframe.remove();
        //         });
        //         iframe.src = "https://" + domain + "/index.html#access_token=" + token;
        //         document.body.append(iframe);
        //     });

        // }

        //if(!!token) document.cookie = encodeURIComponent("access_token") + "=" + encodeURIComponent(token) + "; domain=parkingboss.com; path=/";

        // if(!!frontdesk) {
        //     // the frontdesk token is a legacy forms auth one that gives access to the frontdesk app
        //     // the token one gives access to the base api, vs doing backwards compat on forms auth
        //     document.cookie = encodeURIComponent(frontdesk.name) + "=" + encodeURIComponent(frontdesk.token) + "; domain=parkingboss.com; path=/";
        //     if(!!token) document.cookie = encodeURIComponent("access_token") + "=" + encodeURIComponent(token) + "; domain=parkingboss.com; path=/";
        // }

        var r = record(json);

        //console.log(record);

        var url = _.get(r, "destination") || APPNAVIGATEURL;
        var user = _.get(r, "user");

        //header(user);

        //if(forcePassword) _.set(user, "password.change", true);

        if (!_.get(user, "password") && !_.get(user, "updated"))
          _.set(user, "password.change", true); // force password change if no user updated flag

        var changeName = !_.get(user, "name");
        var changePassword = !!_.get(user, "password.change");

        //forcePassword = forcePassword || !!_.get(user, "password.change")

        if (!forceSetup && !forceEdit && !forcePassword && !url) {
          // requires either a name or password, redirect to setup (no cancel)
          if (changeName || changePassword) {
            page.redirect("/users/" + _.get(user, "id") + "/setup");
            return null; // trigger a setup
          }
        }

        if (forcePassword && !url) {
          edit(user, !changePassword, {
            name: {
              request: false,
            },
            email: {
              request: false,
            },
            password: {
              required: true,
            },
          });
        } else if (forceEdit && !url) {
          edit(user, true, {
            password: {
              request: false,
            },
          }); // run edit
        } else if (forceSetup && !url) {
          edit(user, false, {}); // run edit
        } else if (!!url) {
          page.show("/users/" + _.get(user, "id") + "/navigate?url=" + url);
          //window.location.href = url;
        }

        return next;

        //nav(user);
      })
      .then(function (next) {
        if (_.isFunction(next)) return next();

        //console.log("next", next);

        //return page.redirect("/users/" + _.get(next, "id") + "/setup");
      })
      .catch(function (error) {
        console.log("error", error);

        // redirect to login
        page.show("/login");
      });
  }

  function exit(ctx, next) {
    next();
  }

  // handle all user enters
  ["/users/:user", "/users/:user/*"].forEach(function (path) {
    page(path, function (ctx, next) {
      console.log("entering = ", ctx.pathname);

      ctx.user =
        _.get(state, ctx.params.user) ||
        authenticated(ctx.params.user, api.location);
      enter(ctx, next); // kick off
    });
    page.exit(path, exit);
  });

  // need to wireup automatic auth fail...

  // [
  //     "/users/:user"
  // ].forEach(function(path) {
  //     page(path, authFromContext, enter);
  //     page.exit(path, exit);
  // });

  page("/users/:user", function (ctx) {
    page.redirect(
      "/users/" +
        ctx.params.user +
        "/locations" +
        (!!ctx.querystring ? "?" + ctx.querystring : "")
    );
  });

  page("/users/:user/auth/:location", function (ctx) {
    page.redirect(
      "/users/" +
        ctx.params.user +
        "/locations?l=" +
        ctx.params.location +
        (!!ctx.querystring ? "&" + ctx.querystring : "")
    );
  });
  page("/users/:user/auth", function (ctx) {
    page.redirect(
      "/users/" +
        ctx.params.user +
        (!!ctx.querystring ? "?" + ctx.querystring : "")
    );
  });
  // page("/users/:user/edit", function(ctx) {
  //     page.redirect("/users/" + ctx.params.user + (!!ctx.querystring ? ("?" + ctx.querystring) : ""));
  // });
  page(
    "/users/:user/edit",
    function (ctx, next) {
      _.set(ctx, "params.edit", true);
      next();
    },
    enter,
    function (ctx) {}
  );

  page(
    "/users/:user/setup",
    function (ctx, next) {
      _.set(ctx, "params.setup", true);
      next();
    },
    enter,
    function (ctx) {}
  );

  page(
    "/users/:user/password",
    function (ctx, next) {
      _.set(ctx, "params.password", true);
      next();
    },
    enter,
    function (ctx) {}
  );

  postal.subscribe({
    topic: "user.item",
    callback: function (data, envelope) {
      var user = _.get(data, "item");

      _.set(state, _.get(user.id), {
        users: {
          item: user,
        },
      });

      header(user);
      nav(user);

      _.invoke(appInsights, "setAuthenticatedUserContext", user.id);

      //if(!!window.appInsights) appInsights.setAuthenticatedUserContext(user.email);

      _.invoke(window, "HS.beacon.ready", function () {
        _.invoke(window, "HS.beacon.identify", {
          name: user.name,
          email: user.email,
        });
      });

      _.invoke(window, "Intercom", "update", {
        user_id: user.id,
        name: user.name, // Full name
        email: user.email, // Email address
        created_at: Date.parse(_.get(user, "created.utc", user.created)) / 1000, // Signup date as a Unix timestamp
        //custom_launcher_selector:"nav.app a[href='mailto:help@parkingboss.com'][rel='rt']",
      });
    },
  });

  // UPDATE USER
  root.addEventListener("submit", function (e) {
    if (
      !_.invoke(
        e,
        "target.matches",
        "form[data-record='user'],form[data-record='user/setup']"
      )
    )
      return;

    e.preventDefault();

    var form = e.target;

    // make sure only name because otherwise will grab in-process file inputs
    var disable = _.filter(
      form.querySelectorAll("button,input,textarea,select"),
      function (input) {
        input.blur();
        return !input.disabled && input.type !== "hidden";
      }
    );

    if (!!form.matches(":invalid")) return;
    if (
      disable.filter(function (input) {
        return !!input.required && !input.value;
      }).length > 0
    )
      return; // required with no value

    if (form.classList.contains("submitting")) return;

    var pwInput = form.querySelectorAll(
      "input[type='password'],input[name='password']"
    );

    if (
      !_.every(_.map(pwInput, "value"), function (value, index, collection) {
        return value === collection[0];
      })
    ) {
      alert("Password values must match");
      return;
    }

    form.classList.add("submitting");

    var formData = new FormData(form);
    var password = !!_.find(pwInput, function (input) {
      return !!input.value;
    });

    // disable
    disable.forEach(function (input) {
      input.disabled = true;
    });

    var requested = new Date().toISOString();

    Promise.resolve(user)
      .then(function (user) {
        if (!user) return Promise.reject(new Error("No user"));
        return user;
      })
      .then(function (user) {
        // auth apth
        return api.Auth.promise(user, true)
          .then(function (auth) {
            if (!auth) return Promise.reject(new Error("No user session"));
            //if(!json || !json.user) return Promise.reject(new Error("No user session"));
            //onsole.log(json);
            //header(_.get(json, [ "users", "items", _.get(json, "user.item") ]))
            return auth;
          })
          .then(function (auth) {
            return Promise.all([
              api.base(),
              user,
              _.filter([auth.type, auth.token]).join(" "),
            ]).then(function ([base, user, authHeader]) {
              return api.fetch(
                "POST",
                base + "v1/users/" + user + "?ts=" + requested,
                formData,
                authHeader
              );
            });
          });
      })
      .then(function (json) {
        json.requested = requested;

        var user = _.get(json, "users.item");
        if (!!user && typeof user === "string")
          user = _.get(json, ["users", "items", user], user);

        _.set(state, [user.id, "users", "items", user.id], user);
        header(user);

        //edit(user);

        // start over
        if (password) window.location.href = "/login";
        else page.redirect("/users/" + user.id);

        //console.log(json);
      })
      .catch(function (error) {
        alert(_.get(error, "message", "Whoops, something went wrong"));
      })
      .finally(function () {
        form.classList.remove("submitting");
        disable.forEach(function (input) {
          input.disabled = false;
        });
      });
  });

  form.addEventListener("reset", function (e) {});
})(
  page,
  ParkIQ.API,
  document.documentElement,
  document.querySelector("main[data-record='user']"),
  window.postal,
  window.appInsights
);

// RESOLVE USER
(function (page, api) {
  function home() {
    return api.Auth.promise()
      .then(function (auth) {
        //if(!auth) return Promise.reject(new Error("No user session"));
        if (!auth || !auth.user)
          return Promise.reject(new Error("No user session"));
        return auth.user;
      })
      .then(
        function (user) {
          page.show("/users/" + user + "/locations");
        },
        function (error) {
          // redirect to login
          window.location.href = "/login"; // hard go
        }
      );
  }

  function go(location) {
    return api.Auth.promise()
      .then(function (auth) {
        //if(!auth) return Promise.reject(new Error("No user session"));
        if (!auth || !auth.user)
          return Promise.reject(new Error("No user session"));
        return auth.user;
      })
      .then(
        function (user) {
          if (!!location) page.show("/users/" + user + "/auth/" + location);
          //else page.show("/users/" + user + "/locations?single=true");
          else page.show("/users/" + user + "/locations");
        },
        function (error) {
          console.error("error=", error);

          // redirect to login
          window.location.href = "/login"; // hard go
        }
      );
  }

  function logout() {
    return api.Auth.promise()
      .then(function (auth) {
        //if(!auth) return Promise.reject(new Error("No user session"));
        if (!auth || !auth.user)
          return Promise.reject(new Error("No user session"));
        return auth.user;
      })
      .then(
        function (user) {
          page.redirect("/users/" + user + "/logout");
        },
        function (error) {
          // redirect to login
          window.location.href = "/login"; // hard go
        }
      );
  }

  function enter(ctx) {
    if (ctx.query.url) return page("/user/navigate?url=" + ctx.query.url);

    return go(ctx.params.location);

    // try to get the currently logged in user

    // check against api

    // redirect
  }

  function exit(ctx, next) {
    next();
  }

  ["/user/auth/:location", "/user/auth"].forEach(function (path) {
    page(path, enter);
    page.exit(path, exit);
  });

  ["/user"].forEach(function (path) {
    page(path, home);
    page.exit(path, exit);
  });

  ["/user/logout"].forEach(function (path) {
    page(path, logout);
    page.exit(path, exit);
  });

  page.redirect("/", "/user/auth");
})(page, ParkIQ.API);

// locaitons
(function (root, api, page, postal, container) {
  //var delegate = new Delegate(root);

  //var isIE = (!!window.MSInputMethodContext && !!document.documentMode) || window.navigator.userAgent.indexOf("MSIE");

  function get(user) {
    return Promise.resolve(user)
      .then(function (user) {
        if (!user) return Promise.reject(new Error("No user"));
        return user;
      })
      .then(function (user) {
        return api.Auth.promise(user, true);
        //.catch(function() { return null; });
      })
      .then(function (auth) {
        if (!auth) return Promise.reject(new Error("No user session"));
        //if(!auth || !auth.user) return Promise.reject(new Error("No user session"));
        //onsole.log(json);
        return auth;
      })
      .then(function (auth) {
        var requested = new Date().toISOString();

        return Promise.all([
          api.base(),
          user,
          _.filter([auth.type, auth.token]).join(" "),
        ]).then(function ([base, user, authHeader]) {
          //console.log(base, user, auth);

          var now = new Date();
          var requested = now.toISOString();

          return Promise.all([
            api
              .fetch(
                "GET",
                base +
                  "v1/properties?principal=" +
                  user +
                  "&viewpoint=" +
                  encodeURIComponent(requested),
                null,
                authHeader
              )
              .then(function (json) {
                return json;
              }),
            api
              .fetch(
                "GET",
                base +
                  "v1/authorizations?principal=" +
                  user +
                  "&viewpoint=" +
                  encodeURIComponent(requested) +
                  "&valid=" +
                  encodeURIComponent([requested, requested].join("/")),
                null,
                authHeader
              )
              .then(function (json) {
                return _.pick(json, ["items", "authorizations"]); // all authorizations ever for this user...
              }),
          ])
            .then(function (values) {
              return _.merge.apply(_, values);
            })
            .then(function (json) {
              json.requested = requested;

              return json;
            });
        });
      })
      .then(function (json) {
        var locations = _.get(json, "properties.items");

        if (!locations) return json;

        _.each(locations, function (value, id) {
          if ("string" === typeof value)
            locations[id] = _.get(json, ["items", id]);
        });

        var systemAdmin = false; //!!_.get(json, "authorizations.system"); //!!_.get(_.find(_.get(json, "authorizations.items"), { "principal": user, "scope": "AAAAAAAAAAAAAAAAAAAAAA" }), "roles.admin", false);

        //console.log("sys", user, _.get(json, "authorizations.items"), systemAdmin);

        // function(item) {
        //     return _.get(item, "principal") === user && "AAAAAAAAAAAAAAAAAAAAAA" === _.get(item, "scope") && !!_.get(item, "roles.admin")
        // });

        var isDeveloper = false; //[ "062yab2pcs66s1a8d8c2j85pt0", "zb13afe9c54n78j1s9482cn36w", "mekbveqk1s0zxa47stap6dpy68" ].includes(_.get(user, "id") || user);

        var userInfo = {
          parkingboss: false,
          amenityboss: false,
          manager: false,
          patroller: false,
        };

        _.each(locations, function (item) {
          _.set(
            item,
            "address",
            _.get(json, ["items", item.address]) ||
              _.get(json, ["addresses", "items", item.address], item.address)
          );

          var amenities = !!_.get(item, "amenities.enabled");
          var parking = !!_.get(item, "parking.enabled");
          var manager =
            item.manager ||
            (parking && {
              title: "Parking Manager",
              url: "https://parking.communityboss.app/" + item.id + "/manage",
              amenities,
            });
          _.each(
            _.get(item, "amenities.items") || _.get(item, "amenties.items"),
            function (type) {
              if (type == "amenity") amenities = true;
              if (type == "parking") parking = true;
            }
          );

          userInfo.amenityboss = amenities;
          userInfo.parkingboss = parking;
          if (item.format) userInfo[item.format.toLowerCase()] = true;

          //if (manager)
          _.set(item, "apps.manager", {
            ...manager,
            url:
              "/users/" +
              (_.get(user, "id") || user) +
              "/navigate?url=" +
              manager.url,
          });

          if (manager.amenities) {
            _.set(item, "apps.amenitymanager", {
              title: "Amenity Manager",
              url:
                "/users/" +
                (_.get(user, "id") || user) +
                "/navigate?url=https://amenities.communityboss.app/properties/" +
                item.id +
                "/",
            });
          }

          // if(parking || systemAdmin) {

          //     _.set(item, "apps.parkingmanager", {
          //         "title":"Parking Manager",
          //         "url":"/users/" + (_.get(user, "id") || user) + "/navigate?url=https://parking.communityboss.app/" + item.id + "/manage",
          //     });

          // }

          if (parking) {
            if (!!_.get(item, "users.patrollers.enabled"))
              _.set(item, "apps.fieldagent", {
                title: "Field Agent",
                url:
                  "/users/" +
                  (_.get(user, "id") || user) +
                  "/navigate?url=https://fieldagent.parkingboss.com/" +
                  item.id +
                  "/manage" +
                  (systemAdmin ? "?manager=true" : ""),
              });
          }
          if (parking || systemAdmin) {
            if (!!_.get(item, "reports"))
              _.set(item, "apps.reports", {
                title: "Reports",
                url:
                  "/users/" +
                  (_.get(user, "id") || user) +
                  "/navigate?url=https://reports.communityboss.app/" +
                  item.id +
                  "/reports",
              });
          }

          if (parking) {
            // if(!!_.get(item, "map")) _.set(item, "apps.map", {
            //     "title":"Smart Map",
            //     "url":"/users/" + (_.get(user, "id") || user) + "/navigate?url=https://manager.parkingboss.com/" + item.id + "/map",
            // });

            // if(!!_.get(item, "media.enabled") && "smart-decal" === _.get(item, "media.format")) _.set(item, "apps.fieldagent4", {
            //     "title":"Decal Scanner",
            //     "url":"/users/" + (_.get(user, "id") || user) + "/navigate?url=https://validate.parkingboss.app/" + item.id,
            //     //"url":"/users/" + (_.get(user, "id") || user) + "/navigate?url=https://boss.parkingboss.com/locations/" + item.id,
            // });

            _.set(item, "apps.va", {
              title: "Parking Attendant",
              url: _.get(item, "url"),
            });
          }

          // if(systemAdmin || item.format === "demo") {
          //     _.set(item, "apps.boss", {
          //         "title":"Boss",

          //         //"url":"/users/" + (_.get(user, "id") || user) + "/navigate?url=https://boss.parkingboss.com/locations/" + item.id,
          //         "url":"/users/" + (_.get(user, "id") || user) + "/navigate?url=https://boss.parkingboss.com/dev/properties/" + item.id,
          //     });
          // }

          if (systemAdmin) {
            // _.set(item, "apps.boss2", {
            //     "title":"Boss Dev",
            //     "replace":false,
            //     //"url":"/users/" + (_.get(user, "id") || user) + "/locations/" + item.id + "/configure",
            //     "url":"/users/" + (_.get(user, "id") || user) + "/navigate?url=https://boss.parkingboss.com/dev/properties/" + item.id,
            // });

            if (parking) {
              _.set(item, "apps.fieldagent2", {
                title: "FA Dev",
                url:
                  "/users/" +
                  (_.get(user, "id") || user) +
                  "/navigate?url=https://fieldagent.parkingboss.dev/" +
                  item.id,
                //"url":"/users/" + (_.get(user, "id") || user) + "/navigate?url=https://parkingboss.dev/agent/properties/" + item.id,
                //"url":"/users/" + (_.get(user, "id") || user) + "/navigate?url=https://boss.parkingboss.com/locations/" + item.id,
              });

              _.set(item, "apps.admin2", {
                title: "Admin Dev",
                url:
                  "/users/" +
                  (_.get(user, "id") || user) +
                  "/navigate?url=https://parkingboss.dev/admin/properties/" +
                  item.id,
              });

              if (!!_.get(item, "enforcement.background.enabled"))
                _.set(item, "apps.oversight", {
                  title: "Sherlock Dev",
                  url:
                    "/users/" +
                    (_.get(user, "id") || user) +
                    "/locations/" +
                    item.id +
                    "/sherlock",
                  //"url":"/users/" + (_.get(user, "id") || user) + "/navigate?url=https://overwatch.parkingboss.app?property=" + item.id,
                  //"url":"/users/" + (_.get(user, "id") || user) + "/navigate?url=https://boss.parkingboss.com/locations/" + item.id,
                });
              if (!!_.get(item, "reports"))
                _.set(item, "apps.reports-beta", {
                  title: "Reports Dev",
                  url:
                    "/users/" +
                    (_.get(user, "id") || user) +
                    "/navigate?url=https://reports.parkingboss.dev/" +
                    item.id +
                    "/reports",
                });
            }

            _.set(item, "apps.tenantimporter", {
              title: "Tenant Importer",
              url:
                "/users/" +
                (_.get(user, "id") || user) +
                "/navigate?url=https://tenants.parkingboss.app/properties/" +
                item.id +
                "/tenants/import",
              //"url":"/users/" + (_.get(user, "id") || user) + "/navigate?url=https://boss.parkingboss.com/locations/" + item.id,
            });
          }

          //this applies to sysadmins too...
          if (!_.get(item, "users.patrollers.enabled")) {
            _.unset(item, "apps.fieldagent");
          }

          if (isDeveloper) {
            // developers
            _.set(item, "apps.fieldagent3", {
              title: "FA Local",
              url:
                "/users/" +
                (_.get(user, "id") || user) +
                "/navigate?url=http://localhost:7007/" +
                item.id,
              //"url":"/users/" + (_.get(user, "id") || user) + "/navigate?url=https://boss.parkingboss.com/locations/" + item.id,
            });

            // developers
            _.set(item, "apps.mediascanner2", {
              title: "MediaScanner Local",
              url:
                "/users/" +
                (_.get(user, "id") || user) +
                "/navigate?url=http://localhost:7077/" +
                item.id,
              //"url":"/users/" + (_.get(user, "id") || user) + "/navigate?url=https://boss.parkingboss.com/locations/" + item.id,
            });
          }
        });

        _.each(_.get(json, "authorizations.items"), function (item) {
          if ("string" === typeof item) item = _.get(json, ["items", item]);

          // check validity?

          if (user != _.get(item, "principal")) return; // no this user?

          // if(_.includes(
          //     _.get(locations, [ item.scope, "admin"], ""),
          //     "frontdesk."
          // )) return // don't override frontdesk

          if (!!_.get(item, "roles.admin")) userInfo.manager = true;
          if (!!_.get(item, "roles.patrol")) userInfo.patroller = true;

          if (!_.get(item, "roles.admin")) {
            _.unset(locations, [item.scope, "apps", "parkingmanager"]); // no access to admin app
            _.unset(locations, [item.scope, "apps", "manager"]); // no access to admin app
          } else {
            var fa = _.get(locations, [item.scope, "apps", "fieldagent"]);
            //console.log(fa);
            if (!!fa) fa.url = fa.url + "?manager=true";
          }

          // IS PATROL
          if (_.get(item, "roles.patrol")) {
            _.unset(locations, [item.scope, "apps", "amenitymanager"]); // no access to amenity manager
          }

          //if(!_.get(item, "roles.admin")) _.unset(locations, [ item.scope, "apps", "reports"]); // no access to reports app
          if (!_.get(item, "roles.admin"))
            _.unset(locations, [item.scope, "apps", "map"]); // no access to map app
          if (!_.get(item, "roles.admin"))
            _.unset(locations, [item.scope, "apps", "boss"]); // no access to boss app of any kind

          // _.set(_.get(locations, item.scope), "admin", "https://" + (
          //     !!_.get(item, "roles.admin") ? "manager.parkingboss.com"
          //     : !!_.get(item, "roles.patrol") ? "fieldagent.parkingboss.com"
          //     : "manager.parkingboss.com"
          // ) + "/" + _.get(item, "scope") + "/manage");

          // _.set(_.get(locations, item.scope), "roles.title", (
          //     !!_.get(item, "roles.admin") ? "Manager"
          //     : !!_.get(item, "roles.patrol") ? "Field Agent"
          //     : "Manager"
          // ));
        });

        _.each(_.get(json, "photos.items"), function (item, id) {
          //_.set(item, "url", _.get(item, "url").replace("https://upload.parkingboss.com/files/", "https://parking-uploads.imgix.net/") + "?auto=compress,format&fit=crop&h=250&w=500&crop=focalpoint");
          //_.set(item, "url", _.get(item, "url");

          _.set(locations, [id, "photo"], item);
        });

        // clean up photos
        _.each(locations, function (item) {
          if (!item || !item.photo || !item.photo.url) return;
          _.set(
            item,
            "photo.url",
            _.get(item, "photo.url").replace(
              "https://upload.parkingboss.com/files/",
              "https://parking-uploads.imgix.net/"
            ) + "?auto=compress,format&fit=crop&h=250&w=500&crop=focalpoint"
          );
        });

        // if not sys admin, fill in missing photos with street view
        if (!systemAdmin)
          _.each(_.reject(locations, "photo"), function (item) {
            _.set(
              item,
              "photo.url",
              "https://maps.googleapis.com/maps/api/streetview?size=500x250&location=" +
                _.get(item, "address.street") +
                ", " +
                _.get(item, "address.city") +
                ", " +
                _.get(item, "address.state") +
                " " +
                _.get(item, "address.postal") +
                "&key=AIzaSyA2Nc7S3mbMQDw463BhINRHH1XNyl38DK4"
            );

            //_.set(item, "photo.url", "https://maps.googleapis.com/maps/api/streetview?size=500x250&location=" + _.get(item, "address.latitude") + "," + _.get(item, "address.longitude") + "&key=AIzaSyA2Nc7S3mbMQDw463BhINRHH1XNyl38DK4");
          });

        if (window.Intercom)
          window.Intercom(
            "update",
            _.merge(
              {
                user_id: user.id,
                email: user.email,
              },
              userInfo
            )
          );

        return json;

        // return Promise.resolve(userData).then(function(userData) {

        //     var user = _.get(userData, "users.item");
        //     if(!!user && typeof user === "string") user = _.get(userData, [ "users", "items", user ], user);

        //     if(!!_.get(user, "admin")) {

        //         // system admin

        //         _.each(locations, function(item) {

        //             _.set(item, "apps.boss", {
        //                 "title":"Boss",
        //                 "url":"/users/" + (_.get(user, "id") || user) + "/navigate?url=https://boss.parkingboss.com/locations/" + item.id,
        //             });
        //         });

        //     }

        //     return json;

        // });
      })
      .then(function (json) {
        // publish

        var locations = _.get(json, "properties.items");

        if (!locations) return json;

        _.each(locations, function (value, id) {
          if ("string" === typeof value)
            locations[id] = _.get(json, ["items", id]);
        });

        postal.publish({
          topic: "user.locations.items",
          data: {
            generated: json.generated,
            requested: json.requested,
            user: user,
            count: _.size(locations),
            items: _.map(locations),
          },
        });

        postal.publish({
          topic: "locations.items",
          data: {
            generated: json.generated,
            requested: json.requested,
            count: _.size(locations),
            items: _.map(locations),
          },
        });

        return json;
      })
      .catch(function (error) {
        console.log("locations.get=", error);
      });
  }

  var apps =
    '<% _.each(_.get(item, "apps"), function(item) { %><a href="<%= _.get(item, "url") %>" title="<%= _.get(item, "title") %>" target="<%= !!_.get(item, "replace") ? "_blank" : "" %>"><%= _.get(item, "title") %></a><% }); %>';

  var info =
    '<figure><% if(!!_.get(item, "photo")) { %><img src="<%= _.get(item, "photo.url") %>"><% } %></figure><h1 title="<%= _.get(item, "name") %>"><%= _.get(item, "name") %></h1><p class="address"><a href="https://maps.apple.com?q=<%= _.get(item, "address.street") %>, <%= _.get(item, "address.city") %>, <%= _.get(item, "address.state") %> <%= _.get(item, "address.postal") %>"><span class="street"><%= _.get(item, "address.street") %></span> <span class="city"><%= _.get(item, "address.city") %></span> <span class="state"><%= _.get(item, "address.state") %></span> <span class="postal"><%= _.get(item, "address.postal") %></span></a></p><nav>' +
    apps +
    '<a href="https://maps.apple.com?q=<%= _.get(item, "address.street") %>, <%= _.get(item, "address.city") %>, <%= _.get(item, "address.state") %> <%= _.get(item, "address.postal") %>" target="_blank" title="Directions">Directions</a></nav>';

  var infoWindow = _.template(
    '<aside data-record="locations/<%= _.get(item, "id") %>">' +
      info +
      "</aside>",
    {
      variable: "item",
    }
  );
  var listItem = _.template(
    '<li data-record="locations/<%= _.get(item, "id") %>">' + info + "</li>",
    {
      variable: "item",
    }
  );

  var googleMap = googleMapsInit.then(function (maps) {
    var map = new maps.Map(container.querySelector("figure"), {
      center: new maps.LatLng(39.828175, -98.5795),
      zoom: 3,
      minZoom: 3,

      controlSize: 24,
      backgroundColor: "#CDD6D4",

      styles: [
        {
          featureType: "poi",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "all",
          elementType: "geometry.fill",
          stylers: [
            {
              weight: "2.00",
            },
          ],
        },
        {
          featureType: "all",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#9c9c9c",
            },
          ],
        },
        {
          featureType: "all",
          elementType: "labels.text",
          stylers: [
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "landscape",
          elementType: "all",
          stylers: [
            {
              color: "#f2f2f2",
            },
          ],
        },
        {
          featureType: "landscape",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#ffffff",
            },
          ],
        },
        {
          featureType: "landscape.man_made",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#ffffff",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "all",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "all",
          stylers: [
            {
              saturation: -100,
            },
            {
              lightness: 45,
            },
          ],
        },
        {
          featureType: "road",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#eeeeee",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#7b7b7b",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#ffffff",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "all",
          stylers: [
            {
              visibility: "simplified",
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit",
          elementType: "all",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "all",
          stylers: [
            {
              color: "#46bcec",
            },
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#c8d7d4",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#070707",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#ffffff",
            },
          ],
        },
      ],

      mapTypeId: maps.MapTypeId.ROADMAP,
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
        //style: maps.MapTypeControlStyle.DROPDOWN_MENU,
        position: maps.ControlPosition.RIGHT_TOP,
      },
      zoomControl: true,
      zoomControlOptions: {
        position: maps.ControlPosition.LEFT_TOP,
      },
      rotateControl: true,
      rotateControlOptions: {
        position: maps.ControlPosition.LEFT_TOP,
      },
      scaleControl: true,
      streetViewControl: false,
      streetViewControlOptions: {
        //position: maps.ControlPosition.LEFT_TOP
      },
      fullscreenControl: false,
    });

    return map;
  });

  var markers = {};

  var user = null;
  var beta = true;
  var sys = false;

  var form = container.querySelector("form");
  //console.log("form", form);
  if (!form) return;
  var list = container.querySelector("nav > ul");
  var input = form.querySelector("input");

  var searcher = null;

  function lookup(val) {
    if (!searcher)
      return Promise.resolve({
        query: val,
        items: [],
      });
    return Promise.resolve({
      query: val,
      items: searcher.search(val),
    });
    return api.Locations.lookup(val, true, true);
  }

  var lookups = {
    location: lookup,
  };

  var maxSinglePointZoom = 15;

  function location(html, item) {
    if (!!searcher) searcher.add(item);
    //console.log(item);
    return (html += listItem(item));
  }

  function mapped(locations) {
    googleMap.then(function (map) {
      var points = false;
      var bounds = new google.maps.LatLngBounds();

      //console.log("mapping", locations);

      _.each(locations, function (item) {
        //     return _.merge(_.pick(item.address, [ "name", "latitude", "longitude", "street", "city", "state", "postal" ]), _.pick(item, [ "admin", "roles" ]));
        // }), function(point) {

        //console.log(point);

        var point = _.pick(item.address, ["latitude", "longitude"]);

        if (!point.latitude || !point.longitude) return;
        var position = new google.maps.LatLng(point.latitude, point.longitude);
        points = true;
        bounds.extend(position);
        var marker = new google.maps.Marker({
          position: position,
          icon: "data:image/svg+xml,%3Csvg width='25' height='32' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M12.73 1A11.73 11.73 0 0 0 1 12.78c.03 1.62.36 3.23.97 4.73C5.5 25.12 12.46 31 12.73 31c.28 0 7.23-5.89 10.77-13.57A11.71 11.71 0 0 0 12.73 1z' stroke='%23FFF' fill='%231A1919' fill-rule='nonzero'/%3E%3Cpath d='M19 10.93a.68.68 0 0 0-.7-.66h-.82l-.6-1.5a1.7 1.7 0 0 0-1-.88s-1.04-.29-3.18-.29-3.18.29-3.18.29c-.4.1-.85.5-1 .88l-.6 1.5h-.83c-.38 0-.69.3-.69.66 0 .37.31.67.7.67h.29l-.22.53c-.16.46-.25.93-.26 1.42v3.15c0 .47.4.85.88.85.47 0 .87-.37.88-.85v-.49h8.06v.49c0 .47.4.85.88.85s.87-.37.88-.85v-3.15c0-.4-.12-1.04-.26-1.41l-.22-.53h.3c.38 0 .69-.31.69-.67zm-10.37.2l.71-1.84c.05-.14.2-.24.33-.24h6.06c.13 0 .28.11.33.24l.71 1.84c.05.13-.02.23-.16.23H8.79c-.13 0-.21-.1-.16-.23zm.1 3.26a.8.8 0 0 1-.82-.79.8.8 0 0 1 .82-.79.8.8 0 0 1 .82.79.8.8 0 0 1-.82.79zm7.94 0a.8.8 0 0 1-.82-.79.8.8 0 0 1 .82-.79.8.8 0 0 1 .82.79.8.8 0 0 1-.82.79z' fill='%23FFF'/%3E%3C/g%3E%3C/svg%3E",
          title: point.name,
          map: map,
        });
        _.set(markers, item.id, marker);
        var infowindow = new google.maps.InfoWindow({
          content: infoWindow(item),
        });

        marker.addListener("click", function () {
          infowindow.open(map, marker);
        });
      });

      if (!points) return;

      //map.fitBounds(bounds);

      map.setCenter(bounds.getCenter());

      google.maps.event.addListenerOnce(
        map,
        "bounds_changed",
        function (event) {
          map.setZoom(map.getZoom() - 1);

          //console.log("zoom", map.getZoom())
          if (map.getZoom() > maxSinglePointZoom) {
            map.setZoom(maxSinglePointZoom);
          }
        }
      );
      map.fitBounds(bounds, 2);
    });
  }

  function goToOnMap(item) {
    var marker = _.get(markers, _.get(item, "id") || item);
    if (!marker) return;
    var map = marker.getMap();
    map.panTo(marker.getPosition());
    map.setZoom(maxSinglePointZoom);
    google.maps.event.trigger(marker, "click");
  }

  function locations(locations) {
    // if(!searcher && _.size(locations) >= 20) searcher = new FastFuzzy.Searcher([], {
    //     threshold:0.8,
    //     //ignoreSymbols:false,
    //     keySelector:function(item) {
    //         return [
    //             item.name,
    //             item.address.street,
    //             item.address.city,
    //             item.address.state,
    //         ];
    //     },
    // });
    //console.log(locations);
    //searcher = new Searcher(locations);
    list.innerHTML = _.reduce(locations, location, "");
  }

  function filter(locations) {
    //console.log(locations);
    //list.innerHTML = _.reduce(locations, location, "");

    var mapped = _.keyBy(locations, function (item) {
      return "locations/" + item.id;
    });
    _.each(list.children, function (item) {
      //var hide = !!mapped[elem.dataset.record];
      _.invoke(
        item,
        !_.get(mapped, item.getAttribute("data-record"))
          ? "setAttribute"
          : "removeAttribute",
        "data-filter-match",
        "false"
      );
    });
  }

  function header(locations) {
    //console.log(_.size(locations));
    container.querySelector("nav >header > h1").innerHTML =
      (_.size(locations) || 0) +
      " Location" +
      (1 !== _.size(locations) ? "s" : "");
  }

  var builders = {
    location: location,
  };

  var prevVal = "";

  var searchEvent = "input";

  var nav = input.closest("nav");

  function scrollTop() {
    _.each([nav, root, document.body, window], function (item) {
      if (!item || !item.scrollTop) return;
      item.scrollTop = 0;
    });
  }

  input.addEventListener(searchEvent, function (e) {
    //console.log(e);

    // oninput takes care of actual changes, not just keypresses

    var name = this.getAttribute("name");
    var lookup = lookups[name];
    if (!lookup) return;
    //var input = this;
    //form = form = this.closest("form");
    //list = list || form.querySelector("ul.datalist");

    var val = input.value || "";
    //console.log("val", val);
    if (!!val) val = val.toUpperCase();
    //if(!val) while(list.firstChild) list.firstChild.remove();

    //console.log(lookup, val);

    lookup(val).then(function (results) {
      console.log("results", results);

      //console.log(tenants);

      var currentVal = input.value || "";
      //console.log("currentVal", currentVal);
      if (!!currentVal) currentVal = currentVal.toUpperCase();

      if (input.getAttribute("name") !== name) return; // type has changed
      if (results.query != currentVal) return; // runtime check to account for delay, if any

      var items = results.items;

      // render list
      filter(items);
    });

    scrollTop();

    form.dispatchEvent(
      new CustomEvent("track", {
        bubbles: true,
        detail: {
          category: "locationsearch",
          action: "suggest",
          label: val,
        },
      })
    );
  });

  form.addEventListener("submit", function (e) {
    e.preventDefault();
    window.blur();
  });

  form.addEventListener("reset", function (e) {
    input.value = "";
    input.dispatchEvent(new CustomEvent(searchEvent, { bubbles: true }));
  });

  var inited = false;

  postal.subscribe({
    topic: "user.locations.items",
    callback: function (data, envelope) {
      // save new on update
      //beta =
      //sys = _.get(data, "user.admin", false);

      //console.log(data);
      //console.log("beta", beta);
      var u = _.get(data, "user.id", data.user);
      if (u === user) return; // already setup this user...
      user = u;
      //if(!!inited) return;
      if (_.size(data.items) < 20) form.remove();
      else form.setAttribute("action", ".");
      locations(_.sortBy(data.items, ["name"]));
      header(data.items);
      mapped(data.items);
      inited = true;
    },
  });

  // WHY IS THIS NEEDED?
  // container.addEventListener("click", function(e) {

  //     if(!_.invoke(e, "target.matches", "a[href]:not([target='_blank'])")) return;
  //     if(!!e.metaKey) return;

  //     //e.preventDefault();

  //     //var link = e.target;

  //     _.each([ "data-records", "data-record" ], function(attr) {
  //         root.removeAttribute(attr);
  //     });

  //     // Promise.join(_.get(e, "target.href"), Promise.delay(400), function(url, auth) {
  //     //     page.show("/users/" + (_.get(user, "id") || user) + "/navigate?url=" + url); // send along
  //     // });

  // });

  // LIST MAPS LINKS
  container.addEventListener("click", function (e) {
    if (
      !_.invoke(
        e,
        "target.matches",
        "li[data-record^='locations/'] nav a[href*='maps.']"
      )
    )
      return;

    if (_.get(container.querySelector("nav + figure"), "offsetHeight", 0) <= 0)
      return; // hidden, use defaults

    e.preventDefault();

    goToOnMap(
      e.target
        .closest("li[data-record^='locations/']")
        .dataset.record.split("/")[1]
    );
  });

  page("/users/:user/logout", function (ctx, next) {
    locations({});
    header({});
    next();
  });

  function defaultAppUrl(item) {
    return (
      _.get(item, "apps.manager.url") || _.get(item, "apps.fieldagent.url")
    );
    return (
      _.get(item, "apps.frontdesk.url") ||
      _.get(item, "apps.manager.url") ||
      _.get(item, "apps.fieldagent.url")
    );
  }

  page("/users/:user/locations", function (ctx, next) {
    root.setAttribute(
      "data-records",
      "users/" + ctx.params.user + "/locations"
    );
    get(ctx.params.user, ctx.user).then(function (json) {
      var items = _.get(json, "properties.items");
      _.each(locations, function (value, id) {
        if ("string" === typeof value) items[id] = _.get(json, ["items", id]);
      });

      //console.log("selected?",  _.get(ctx, "query.l"), api.location);

      var item = _.get(items, _.get(ctx, "query.l") || api.location);
      if (!!item) {
        // STOP THIS
        //page.redirect("/users/" + ctx.params.user + "/navigate?url=" + defaultAppUrl(item)); // send along
        return json;
      }

      //console.log("single?", ctx, json, !!_.get(ctx, "query.single"), _.get(json, "locations.count", 0) === 1,  !!(item = _.first(_.map(items))));

      // must be only item and not frontdesk
      if (
        !!_.get(ctx, "query.single") &&
        _.get(json, "locations.count", 0) === 1 &&
        !!(item = _.first(_.map(items))) &&
        !item.admin &&
        !item.map
      ) {
        // single case;

        // STOP THIS
        //page.redirect("/users/" + ctx.params.user + "/navigate?url=" + defaultAppUrl(item)); // send along
        return json;
      }

      return json;
    });
    //next();
  });

  page("/users/:user/locations/:location/map", function (ctx, next) {
    root.setAttribute(
      "data-records",
      "users/" + ctx.params.user + "/locations/" + ctx.params.location + "/map"
    );

    _.set(
      root.querySelector("main[data-records='location/map'] > figure"),
      "innerHTML",
      '<iframe src="/users/' +
        ctx.params.user +
        "/navigate?url=https://customapps.parkingboss.com/" +
        ctx.params.location +
        "/parkingmap/index.html?v=" +
        new Date().toISOString() +
        '"></iframe>'
    );
  });

  page("/users/:user/locations/:location/maps", function (ctx, next) {
    root.setAttribute(
      "data-records",
      "users/" + ctx.params.user + "/locations/" + ctx.params.location + "/map"
    );

    _.set(
      root.querySelector("main[data-records='location/map'] > figure"),
      "innerHTML",
      '<iframe src="/users/' +
        ctx.params.user +
        "/navigate?url=" +
        encodeURIComponent(
          "https://maps.parkingboss.app?property=" +
            ctx.params.location +
            "&v=" +
            new Date().toISOString() +
            "&permits=true&admin=true"
        ) +
        '"></iframe>'
    );
  });

  page("/users/:user/locations/:location/configure", function (ctx, next) {
    root.setAttribute(
      "data-records",
      "users/" +
        ctx.params.user +
        "/locations/" +
        ctx.params.location +
        "/configure"
    );

    var fig = root.querySelector(
      "main[data-records='location/configure'] > figure"
    );

    var url =
      "/users/" +
      ctx.params.user +
      "/navigate?url=https://boss.parkingboss.com/locations/" +
      ctx.params.location +
      "?v=" +
      new Date().toISOString();

    if (_.get(fig, "offsetHeight", 0) > 0) {
      _.set(fig, "innerHTML", '<iframe src="' + url + '"></iframe>');
    } else {
      page.redirect(url);
    }
  });

  page("/users/:user/locations/:location/sherlock", function (ctx, next) {
    root.setAttribute(
      "data-records",
      "users/" +
        ctx.params.user +
        "/locations/" +
        ctx.params.location +
        "/configure"
    );

    var fig = root.querySelector(
      "main[data-records='location/configure'] > figure"
    );

    //APPNAVIGATEURL = "https://overwatch.parkingboss.dev?property=" + ctx.params.location + "&days=28&v=" + (new Date()).toISOString();

    var url =
      "/users/" +
      ctx.params.user +
      "/navigate?url=" +
      encodeURIComponent(
        "https://overwatch.parkingboss.dev?property=" +
          ctx.params.location +
          "&days=28&v=" +
          new Date().toISOString()
      );

    if (_.get(fig, "offsetHeight", 0) > 0) {
      _.set(fig, "innerHTML", '<iframe src="' + url + '"></iframe>');
    } else {
      page.redirect(url);
    }
  });

  page("/users/:user/health", function (ctx, next) {
    root.setAttribute(
      "data-records",
      "users/" + ctx.params.user + "/locations/configure/health"
    );

    var fig = root.querySelector(
      "main[data-records='location/configure'] > figure"
    );

    var url =
      "/users/" +
      ctx.params.user +
      "/navigate?url=https://boss.parkingboss.com/health.html?v=" +
      new Date().toISOString();

    if (_.get(fig, "offsetHeight", 0) > 0) {
      _.set(fig, "innerHTML", '<iframe src="' + url + '"></iframe>');
    } else {
      page.redirect(url);
    }
  });

  page("/users/:user/locations/new", function (ctx, next) {
    root.setAttribute(
      "data-records",
      "users/" + ctx.params.user + "/locations/configure/new"
    );

    var fig = root.querySelector(
      "main[data-records='location/configure'] > figure"
    );

    var url =
      "/users/" +
      ctx.params.user +
      "/navigate?url=https://boss.parkingboss.com/setup.html?v=" +
      new Date().toISOString();

    if (_.get(fig, "offsetHeight", 0) > 0) {
      _.set(fig, "innerHTML", '<iframe src="' + url + '"></iframe>');
    } else {
      page.redirect(url);
    }
  });

  // page("/users/:user/health", function(ctx, next) {

  //     root.setAttribute("data-records", "users/" + ctx.params.user + "/locations/health");

  //     var fig = root.querySelector("main[data-records='locations/health'] > figure");

  //     var url = "/users/" + ctx.params.user + "/navigate?url=https://boss.parkingboss.com/health.html?v=" + (new Date()).toISOString();

  //     if(_.get(fig, "offsetHeight", 0) > 0) {
  //         _.set(fig, "innerHTML", '<iframe src="' + url + '"></iframe>');
  //     } else {
  //         page.redirect(url);
  //     }

  // });
})(
  document.documentElement,
  ParkIQ.API,
  page,
  postal,
  document.documentElement.querySelector("main[data-records='user/locations']")
);

// logout
(function (page, api, root, appInsights) {
  function header() {
    //console.log("header", user);
    _.set(
      root.querySelector("body > header"),
      "innerHTML",
      "<h1></h1></h2></h2>"
    );
  }

  function enter(ctx) {
    //root.setAttribute("data-record", container.getAttribute("data-record"));

    var user = ctx.params.user;
    api.Auth.remove(user, "parkingboss.com");

    _.invoke(appInsights, "clearAuthenticatedUserContext");

    header();

    //page.redirect("/login");

    window.location.href = "/login"; // force a full page reload
  }

  function exit(ctx, next) {
    next();
  }

  // log out a specific user
  ["/users/:user/logout"].forEach(function (path) {
    page(path, enter);
    page.exit(path, exit);
  });
})(page, ParkIQ.API, document.documentElement, window.appInsights);

export default self;
