import { api, logIn } from "./util/auth";
import { router, route } from "./util/router";

// router("/user/logout", function (ctx, next) {
//     console.log("calling new logout");
//     api.logOut(true);
//     if (next) next();
// });

import style from "../styles/app.scss";
import legacy from "./legacy/app";
import App from "./App.svelte";
//window.page = router;

const app = new App({
    target: document.body || document.querySelector("body"),
});


if(!window.CSS || !window.CSS.supports || !CSS.supports("display", "grid")) throw new Error("Unsupported Client");

router("/login", () => {
    return logIn(location.origin);
});
router("/login/reset", () => {
    location.href = "https://auth.communityboss.app/login/reset";
});

router.start();

// token.subscribe(token => {
//     if(!!token) document.cookie = encodeURIComponent("access_token") + "=" + encodeURIComponent(token) + "; domain=parkingboss.com; path=/";

//     if(!!token) {

//         // this doesn't work in safari - blocked
//         for(const domain of [ "smartdecal.parkingboss.com", "smartpermit.app" ]) {
//             const iframe = document.createElement("iframe");
//             iframe.setAttribute("style","position: absolute !important; opacity: 0 !important; width: 1px !important; height: 1px !important; top: 0 !important; left: 0 !important; border: none !important; display: block !important; z-index: -1 !important;");
//             iframe.addEventListener("load", function() {
//                 iframe.remove();
//             });
//             iframe.src = "https://" + domain + "/index.html#access_token=" + token;
//             document.body.append(iframe);
//         }
        
//     }
// })